import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { Message } from '@mship/design-ng/base/api';
import { I18nService } from '@mship/design-ng/custom/i18n';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  static httpErrors = new BehaviorSubject<Message[]>([]);

  constructor(private i18nService: I18nService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        AuthInterceptor.httpErrors.next([
          ...AuthInterceptor.httpErrors.getValue(),
          {
            severity: 'error',
            summary: this.i18nService.instant('serviceDlp.errorTitle'),
            detail: error?.error?.code
              ? this.i18nService.instant('serviceDlp.error.' + error.error.code, error.error.parameters)
              : error.message,
          },
        ]);
        return throwError(() => error);
      }),
    );
  }
}
