@if ((isDLPEnabled | async) !== false) {
  <mship-app-shell
    [headline]="'serviceDlp.general.title' | translate"
    [subHeadline]="'serviceDlp.general.subtitle' | translate"
    [stage]="stage"
    [version]="version"
    [showSessionMenu]="!isChildWindow"
    [launchMenuConfig]="launchMenuConfig()"
    [disableFavorites]="!favoritesAllowed()"
    (whenOpenApp)="openApp($event)"
    (whenToggleFavorite)="toggleFavorite($event)"
  >
    <ng-container id="mship-app-shell-actions-top">
      @if (isRootWindow) {
        <li class="mship-app-shell-action">
          <dlp-window-menu></dlp-window-menu>
        </li>
      }
      @if (isChildWindow) {
        <li class="mship-app-shell-action">
          <dlp-instance-menu></dlp-instance-menu>
        </li>
      }
      <li class="mship-app-shell-action" [title]="'serviceDlp.notifications.title' | translate">
        <dlp-notification-manager></dlp-notification-manager>
      </li>
    </ng-container>
    <dlp-panels id="mship-app-shell-main"></dlp-panels>
  </mship-app-shell>
  <p-contextMenu [global]="true" [model]="items"></p-contextMenu>
} @else {
  <main>The Dynamic Launch Panel is already open in another window</main>
}
