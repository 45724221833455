import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ToastModule } from '@mship/design-ng/base/toast';
import { IContentRenderer } from 'dockview-core';
import { GroupPanelPartInitParameters } from 'dockview-core/dist/cjs/dockview/types';
import { GridStatusDashboardChartComponent } from './chart/grid-status-dashboard-chart.component';
import { GridStatusDashboardAlarmsComponent } from './alarms/grid-status-dashboard-alarms.component';
import { GridStatusDashboardGridTimeComponent } from './grid-time/grid-status-dashboard-grid-time.component';
import { GridStatusDashboardAlarmData, GridStatusDashboardChartData } from './grid-status-dashboard.interface';
import { BehaviorSubject, tap, timer } from 'rxjs';

@Component({
  selector: 'dlp-grid-status-dashboard-panel',
  standalone: true,
  imports: [
    ToastModule,
    GridStatusDashboardChartComponent,
    GridStatusDashboardAlarmsComponent,
    GridStatusDashboardGridTimeComponent,
  ],
  templateUrl: './grid-status-dashboard-panel.component.html',
  styleUrl: './grid-status-dashboard-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridStatusDashboardPanelComponent implements IContentRenderer {
  @ViewChild('element') elementRef!: ElementRef;

  params!: GroupPanelPartInitParameters;
  chartDataSubject = new BehaviorSubject<GridStatusDashboardChartData | undefined>(undefined);
  alertDataSubject = new BehaviorSubject<GridStatusDashboardAlarmData | undefined>(undefined);

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  get element() {
    this.changeDetectorRef.detectChanges();
    return this.elementRef.nativeElement;
  }

  init(groupPanelInitParameters: GroupPanelPartInitParameters): void {
    timer(0, 15 * 1000)
      .pipe(
        tap(() => {
          this.chartDataSubject.next({
            alarms: this.randomInt(0, 100),
            congestions: this.randomInt(0, 100),
            frequencyStability: this.randomInt(0, 100),
            operationReserve: this.randomInt(0, 100),
            outages: this.randomInt(0, 100),
            voltageQuality: this.randomInt(0, 100),
          });
          this.alertDataSubject.next({
            critical: this.randomInt(0, 5),
            high: this.randomInt(0, 10),
            medium: this.randomInt(0, 20),
            info: this.randomInt(0, 20),
          });
        }),
      )
      .subscribe();
    this.params = groupPanelInitParameters;
  }

  randomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
  }
}
