import { getRelativeLocation, getGridLocation, orthogonal } from '../gridview/gridview';
import { directionToPosition, Droptarget } from '../dnd/droptarget';
import { tail, sequenceEquals, remove } from '../array';
import { DockviewPanel } from './dockviewPanel';
import { CompositeDisposable, Disposable } from '../lifecycle';
import { Event, Emitter, addDisposableWindowListener } from '../events';
import { Watermark } from './components/watermark/watermark';
import { sequentialNumberGenerator } from '../math';
import { DefaultDockviewDeserialzier } from './deserializer';
import { DockviewUnhandledDragOverEvent, isGroupOptionsWithGroup, isGroupOptionsWithPanel, isPanelOptionsWithGroup, isPanelOptionsWithPanel } from './options';
import { BaseGrid, toTarget } from '../gridview/baseComponentGridview';
import { DockviewApi } from '../api/component.api';
import { Orientation } from '../splitview/splitview';
import { DockviewDidDropEvent, DockviewWillDropEvent, WillShowOverlayLocationEvent } from './dockviewGroupPanelModel';
import { DockviewGroupPanel } from './dockviewGroupPanel';
import { DockviewPanelModel } from './dockviewPanelModel';
import { getPanelData } from '../dnd/dataTransfer';
import { Overlay } from '../overlay/overlay';
import { addTestId, getDockviewTheme, toggleClass, watchElementResize } from '../dom';
import { DockviewFloatingGroupPanel } from './dockviewFloatingGroupPanel';
import { DEFAULT_FLOATING_GROUP_OVERFLOW_SIZE, DEFAULT_FLOATING_GROUP_POSITION } from '../constants';
import { OverlayRenderContainer } from '../overlay/overlayRenderContainer';
import { PopoutWindow } from '../popoutWindow';
const DEFAULT_ROOT_OVERLAY_MODEL = {
  activationSize: {
    type: 'pixels',
    value: 10
  },
  size: {
    type: 'pixels',
    value: 20
  }
};
function moveGroupWithoutDestroying(options) {
  const activePanel = options.from.activePanel;
  const panels = [...options.from.panels].map(panel => {
    const removedPanel = options.from.model.removePanel(panel);
    options.from.model.renderContainer.detatch(panel);
    return removedPanel;
  });
  panels.forEach(panel => {
    options.to.model.openPanel(panel, {
      skipSetActive: activePanel !== panel,
      skipSetGroupActive: true
    });
  });
}
export class DockviewComponent extends BaseGrid {
  get orientation() {
    return this.gridview.orientation;
  }
  get totalPanels() {
    return this.panels.length;
  }
  get panels() {
    return this.groups.flatMap(group => group.panels);
  }
  get options() {
    return this._options;
  }
  get activePanel() {
    const activeGroup = this.activeGroup;
    if (!activeGroup) {
      return undefined;
    }
    return activeGroup.activePanel;
  }
  get renderer() {
    var _a;
    return (_a = this.options.defaultRenderer) !== null && _a !== void 0 ? _a : 'onlyWhenVisible';
  }
  get api() {
    return this._api;
  }
  get gap() {
    return this.gridview.margin;
  }
  get floatingGroups() {
    return this._floatingGroups;
  }
  constructor(parentElement, options) {
    var _a;
    super(parentElement, {
      proportionalLayout: true,
      orientation: Orientation.HORIZONTAL,
      styles: options.hideBorders ? {
        separatorBorder: 'transparent'
      } : undefined,
      disableAutoResizing: options.disableAutoResizing,
      locked: options.locked,
      margin: options.gap,
      className: options.className
    });
    this.nextGroupId = sequentialNumberGenerator();
    this._deserializer = new DefaultDockviewDeserialzier(this);
    this.watermark = null;
    this._onWillDragPanel = new Emitter();
    this.onWillDragPanel = this._onWillDragPanel.event;
    this._onWillDragGroup = new Emitter();
    this.onWillDragGroup = this._onWillDragGroup.event;
    this._onDidDrop = new Emitter();
    this.onDidDrop = this._onDidDrop.event;
    this._onWillDrop = new Emitter();
    this.onWillDrop = this._onWillDrop.event;
    this._onWillShowOverlay = new Emitter();
    this.onWillShowOverlay = this._onWillShowOverlay.event;
    this._onUnhandledDragOverEvent = new Emitter();
    this.onUnhandledDragOverEvent = this._onUnhandledDragOverEvent.event;
    this._onDidRemovePanel = new Emitter();
    this.onDidRemovePanel = this._onDidRemovePanel.event;
    this._onDidAddPanel = new Emitter();
    this.onDidAddPanel = this._onDidAddPanel.event;
    this._onDidLayoutFromJSON = new Emitter();
    this.onDidLayoutFromJSON = this._onDidLayoutFromJSON.event;
    this._onDidActivePanelChange = new Emitter();
    this.onDidActivePanelChange = this._onDidActivePanelChange.event;
    this._onDidMovePanel = new Emitter();
    this.onDidMovePanel = this._onDidMovePanel.event;
    this._floatingGroups = [];
    this._popoutGroups = [];
    this._onDidRemoveGroup = new Emitter();
    this.onDidRemoveGroup = this._onDidRemoveGroup.event;
    this._onDidAddGroup = new Emitter();
    this.onDidAddGroup = this._onDidAddGroup.event;
    this._onDidActiveGroupChange = new Emitter();
    this.onDidActiveGroupChange = this._onDidActiveGroupChange.event;
    this._moving = false;
    this.overlayRenderContainer = new OverlayRenderContainer(this.gridview.element, this);
    toggleClass(this.gridview.element, 'dv-dockview', true);
    toggleClass(this.element, 'dv-debug', !!options.debug);
    this.addDisposables(this.overlayRenderContainer, this._onWillDragPanel, this._onWillDragGroup, this._onWillShowOverlay, this._onDidActivePanelChange, this._onDidAddPanel, this._onDidRemovePanel, this._onDidLayoutFromJSON, this._onDidDrop, this._onWillDrop, this._onDidMovePanel, this._onDidAddGroup, this._onDidRemoveGroup, this._onDidActiveGroupChange, this._onUnhandledDragOverEvent, this.onDidViewVisibilityChangeMicroTaskQueue(() => {
      this.updateWatermark();
    }), this.onDidAdd(event => {
      if (!this._moving) {
        this._onDidAddGroup.fire(event);
      }
    }), this.onDidRemove(event => {
      if (!this._moving) {
        this._onDidRemoveGroup.fire(event);
      }
    }), this.onDidActiveChange(event => {
      if (!this._moving) {
        this._onDidActiveGroupChange.fire(event);
      }
    }), Event.any(this.onDidAdd, this.onDidRemove)(() => {
      this.updateWatermark();
    }), Event.any(this.onDidAddPanel, this.onDidRemovePanel, this.onDidAddGroup, this.onDidRemove, this.onDidMovePanel, this.onDidActivePanelChange)(() => {
      this._bufferOnDidLayoutChange.fire();
    }), Disposable.from(() => {
      // iterate over a copy of the array since .dispose() mutates the original array
      for (const group of [...this._floatingGroups]) {
        group.dispose();
      }
      // iterate over a copy of the array since .dispose() mutates the original array
      for (const group of [...this._popoutGroups]) {
        group.disposable.dispose();
      }
    }));
    this._options = options;
    this._rootDropTarget = new Droptarget(this.element, {
      canDisplayOverlay: (event, position) => {
        const data = getPanelData();
        if (data) {
          if (data.viewId !== this.id) {
            return false;
          }
          if (position === 'center') {
            // center drop target is only allowed if there are no panels in the grid
            // floating panels are allowed
            return this.gridview.length === 0;
          }
          return true;
        }
        if (position === 'center' && this.gridview.length !== 0) {
          /**
           * for external events only show the four-corner drag overlays, disable
           * the center position so that external drag events can fall through to the group
           * and panel drop target handlers
           */
          return false;
        }
        const firedEvent = new DockviewUnhandledDragOverEvent(event, 'edge', position, getPanelData);
        this._onUnhandledDragOverEvent.fire(firedEvent);
        return firedEvent.isAccepted;
      },
      acceptedTargetZones: ['top', 'bottom', 'left', 'right', 'center'],
      overlayModel: (_a = this.options.rootOverlayModel) !== null && _a !== void 0 ? _a : DEFAULT_ROOT_OVERLAY_MODEL
    });
    this.addDisposables(this._rootDropTarget, this._rootDropTarget.onWillShowOverlay(event => {
      if (this.gridview.length > 0 && event.position === 'center') {
        // option only available when no panels in primary grid
        return;
      }
      this._onWillShowOverlay.fire(new WillShowOverlayLocationEvent(event, {
        kind: 'edge',
        panel: undefined,
        api: this._api,
        group: undefined,
        getData: getPanelData
      }));
    }), this._rootDropTarget.onDrop(event => {
      var _a;
      const willDropEvent = new DockviewWillDropEvent({
        nativeEvent: event.nativeEvent,
        position: event.position,
        panel: undefined,
        api: this._api,
        group: undefined,
        getData: getPanelData,
        kind: 'edge'
      });
      this._onWillDrop.fire(willDropEvent);
      if (willDropEvent.defaultPrevented) {
        return;
      }
      const data = getPanelData();
      if (data) {
        this.moveGroupOrPanel({
          from: {
            groupId: data.groupId,
            panelId: (_a = data.panelId) !== null && _a !== void 0 ? _a : undefined
          },
          to: {
            group: this.orthogonalize(event.position),
            position: 'center'
          }
        });
      } else {
        this._onDidDrop.fire(new DockviewDidDropEvent({
          nativeEvent: event.nativeEvent,
          position: event.position,
          panel: undefined,
          api: this._api,
          group: undefined,
          getData: getPanelData
        }));
      }
    }), this._rootDropTarget);
    this._api = new DockviewApi(this);
    this.updateWatermark();
  }
  addPopoutGroup(itemToPopout, options) {
    var _a, _b, _c;
    if (itemToPopout instanceof DockviewPanel && itemToPopout.group.size === 1) {
      return this.addPopoutGroup(itemToPopout.group, options);
    }
    const theme = getDockviewTheme(this.gridview.element);
    const element = this.element;
    function getBox() {
      if (options === null || options === void 0 ? void 0 : options.position) {
        return options.position;
      }
      if (itemToPopout instanceof DockviewGroupPanel) {
        return itemToPopout.element.getBoundingClientRect();
      }
      if (itemToPopout.group) {
        return itemToPopout.group.element.getBoundingClientRect();
      }
      return element.getBoundingClientRect();
    }
    const box = getBox();
    const groupId = (_b = (_a = options === null || options === void 0 ? void 0 : options.overridePopoutGroup) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : this.getNextGroupId();
    if (itemToPopout.api.location.type === 'grid') {
      itemToPopout.api.setVisible(false);
    }
    const _window = new PopoutWindow(`${this.id}-${groupId}`,
    // unique id
    theme !== null && theme !== void 0 ? theme : '', {
      url: (_c = options === null || options === void 0 ? void 0 : options.popoutUrl) !== null && _c !== void 0 ? _c : '/popout.html',
      left: window.screenX + box.left,
      top: window.screenY + box.top,
      width: box.width,
      height: box.height,
      onDidOpen: options === null || options === void 0 ? void 0 : options.onDidOpen,
      onWillClose: options === null || options === void 0 ? void 0 : options.onWillClose
    });
    const popoutWindowDisposable = new CompositeDisposable(_window, _window.onDidClose(() => {
      popoutWindowDisposable.dispose();
    }));
    return _window.open().then(popoutContainer => {
      var _a;
      if (_window.isDisposed) {
        return;
      }
      if (popoutContainer === null) {
        popoutWindowDisposable.dispose();
        return;
      }
      const gready = document.createElement('div');
      gready.className = 'dv-overlay-render-container';
      const overlayRenderContainer = new OverlayRenderContainer(gready, this);
      const referenceGroup = itemToPopout instanceof DockviewPanel ? itemToPopout.group : itemToPopout;
      const referenceLocation = itemToPopout.api.location.type;
      const group = (_a = options === null || options === void 0 ? void 0 : options.overridePopoutGroup) !== null && _a !== void 0 ? _a : this.createGroup({
        id: groupId
      });
      group.model.renderContainer = overlayRenderContainer;
      if (!(options === null || options === void 0 ? void 0 : options.overridePopoutGroup)) {
        this._onDidAddGroup.fire(group);
      }
      if (itemToPopout instanceof DockviewPanel) {
        this.movingLock(() => {
          const panel = referenceGroup.model.removePanel(itemToPopout);
          group.model.openPanel(panel);
        });
      } else {
        this.movingLock(() => moveGroupWithoutDestroying({
          from: referenceGroup,
          to: group
        }));
        switch (referenceLocation) {
          case 'grid':
            referenceGroup.api.setVisible(false);
            break;
          case 'floating':
          case 'popout':
            this.removeGroup(referenceGroup);
            break;
        }
      }
      popoutContainer.classList.add('dv-dockview');
      popoutContainer.style.overflow = 'hidden';
      popoutContainer.appendChild(gready);
      popoutContainer.appendChild(group.element);
      group.model.location = {
        type: 'popout',
        getWindow: () => _window.window
      };
      this.doSetGroupAndPanelActive(group);
      popoutWindowDisposable.addDisposables(group.api.onDidActiveChange(event => {
        var _a;
        if (event.isActive) {
          (_a = _window.window) === null || _a === void 0 ? void 0 : _a.focus();
        }
      }), group.api.onWillFocus(() => {
        var _a;
        (_a = _window.window) === null || _a === void 0 ? void 0 : _a.focus();
      }));
      let returnedGroup;
      const value = {
        window: _window,
        popoutGroup: group,
        referenceGroup: this.getPanel(referenceGroup.id) ? referenceGroup.id : undefined,
        disposable: {
          dispose: () => {
            popoutWindowDisposable.dispose();
            return returnedGroup;
          }
        }
      };
      popoutWindowDisposable.addDisposables(
      /**
       * ResizeObserver seems slow here, I do not know why but we don't need it
       * since we can reply on the window resize event as we will occupy the full
       * window dimensions
       */
      addDisposableWindowListener(_window.window, 'resize', () => {
        group.layout(window.innerWidth, window.innerHeight);
      }), overlayRenderContainer, Disposable.from(() => {
        if (this.getPanel(referenceGroup.id)) {
          this.movingLock(() => moveGroupWithoutDestroying({
            from: group,
            to: referenceGroup
          }));
          if (!referenceGroup.api.isVisible) {
            referenceGroup.api.setVisible(true);
          }
          if (this.getPanel(group.id)) {
            this.doRemoveGroup(group, {
              skipPopoutAssociated: true
            });
          }
        } else if (this.getPanel(group.id)) {
          const removedGroup = this.doRemoveGroup(group, {
            skipDispose: true,
            skipActive: true
          });
          removedGroup.model.renderContainer = this.overlayRenderContainer;
          removedGroup.model.location = {
            type: 'grid'
          };
          returnedGroup = removedGroup;
        }
      }));
      this._popoutGroups.push(value);
      this.updateWatermark();
    }).catch(err => {
      console.error('dockview: failed to create popout window', err);
    });
  }
  addFloatingGroup(item, options) {
    var _a, _b, _c, _d, _e;
    let group;
    if (item instanceof DockviewPanel) {
      group = this.createGroup();
      this._onDidAddGroup.fire(group);
      this.movingLock(() => this.removePanel(item, {
        removeEmptyGroup: true,
        skipDispose: true,
        skipSetActiveGroup: true
      }));
      this.movingLock(() => group.model.openPanel(item, {
        skipSetGroupActive: true
      }));
    } else {
      group = item;
      const popoutReferenceGroupId = (_a = this._popoutGroups.find(_ => _.popoutGroup === group)) === null || _a === void 0 ? void 0 : _a.referenceGroup;
      const popoutReferenceGroup = popoutReferenceGroupId ? this.getPanel(popoutReferenceGroupId) : undefined;
      const skip = typeof (options === null || options === void 0 ? void 0 : options.skipRemoveGroup) === 'boolean' && options.skipRemoveGroup;
      if (!skip) {
        if (popoutReferenceGroup) {
          this.movingLock(() => moveGroupWithoutDestroying({
            from: item,
            to: popoutReferenceGroup
          }));
          this.doRemoveGroup(item, {
            skipPopoutReturn: true,
            skipPopoutAssociated: true
          });
          this.doRemoveGroup(popoutReferenceGroup, {
            skipDispose: true
          });
          group = popoutReferenceGroup;
        } else {
          this.doRemoveGroup(item, {
            skipDispose: true,
            skipPopoutReturn: true,
            skipPopoutAssociated: false
          });
        }
      }
    }
    function getAnchoredBox() {
      if (options === null || options === void 0 ? void 0 : options.position) {
        const result = {};
        if ('left' in options.position) {
          result.left = Math.max(options.position.left, 0);
        } else if ('right' in options.position) {
          result.right = Math.max(options.position.right, 0);
        } else {
          result.left = DEFAULT_FLOATING_GROUP_POSITION.left;
        }
        if ('top' in options.position) {
          result.top = Math.max(options.position.top, 0);
        } else if ('bottom' in options.position) {
          result.bottom = Math.max(options.position.bottom, 0);
        } else {
          result.top = DEFAULT_FLOATING_GROUP_POSITION.top;
        }
        if (typeof options.width === 'number') {
          result.width = Math.max(options.width, 0);
        } else {
          result.width = DEFAULT_FLOATING_GROUP_POSITION.width;
        }
        if (typeof options.height === 'number') {
          result.height = Math.max(options.height, 0);
        } else {
          result.height = DEFAULT_FLOATING_GROUP_POSITION.height;
        }
        return result;
      }
      return {
        left: typeof (options === null || options === void 0 ? void 0 : options.x) === 'number' ? Math.max(options.x, 0) : DEFAULT_FLOATING_GROUP_POSITION.left,
        top: typeof (options === null || options === void 0 ? void 0 : options.y) === 'number' ? Math.max(options.y, 0) : DEFAULT_FLOATING_GROUP_POSITION.top,
        width: typeof (options === null || options === void 0 ? void 0 : options.width) === 'number' ? Math.max(options.width, 0) : DEFAULT_FLOATING_GROUP_POSITION.width,
        height: typeof (options === null || options === void 0 ? void 0 : options.height) === 'number' ? Math.max(options.height, 0) : DEFAULT_FLOATING_GROUP_POSITION.height
      };
    }
    const anchoredBox = getAnchoredBox();
    const overlay = new Overlay(Object.assign(Object.assign({
      container: this.gridview.element,
      content: group.element
    }, anchoredBox), {
      minimumInViewportWidth: this.options.floatingGroupBounds === 'boundedWithinViewport' ? undefined : (_c = (_b = this.options.floatingGroupBounds) === null || _b === void 0 ? void 0 : _b.minimumWidthWithinViewport) !== null && _c !== void 0 ? _c : DEFAULT_FLOATING_GROUP_OVERFLOW_SIZE,
      minimumInViewportHeight: this.options.floatingGroupBounds === 'boundedWithinViewport' ? undefined : (_e = (_d = this.options.floatingGroupBounds) === null || _d === void 0 ? void 0 : _d.minimumHeightWithinViewport) !== null && _e !== void 0 ? _e : DEFAULT_FLOATING_GROUP_OVERFLOW_SIZE
    }));
    const el = group.element.querySelector('.void-container');
    if (!el) {
      throw new Error('failed to find drag handle');
    }
    overlay.setupDrag(el, {
      inDragMode: typeof (options === null || options === void 0 ? void 0 : options.inDragMode) === 'boolean' ? options.inDragMode : false
    });
    const floatingGroupPanel = new DockviewFloatingGroupPanel(group, overlay);
    const disposable = new CompositeDisposable(group.api.onDidActiveChange(event => {
      if (event.isActive) {
        overlay.bringToFront();
      }
    }), watchElementResize(group.element, entry => {
      const {
        width,
        height
      } = entry.contentRect;
      group.layout(width, height); // let the group know it's size is changing so it can fire events to the panel
    }));
    floatingGroupPanel.addDisposables(overlay.onDidChange(() => {
      // this is either a resize or a move
      // to inform the panels .layout(...) the group with it's current size
      // don't care about resize since the above watcher handles that
      group.layout(group.width, group.height);
    }), overlay.onDidChangeEnd(() => {
      this._bufferOnDidLayoutChange.fire();
    }), group.onDidChange(event => {
      overlay.setBounds({
        height: event === null || event === void 0 ? void 0 : event.height,
        width: event === null || event === void 0 ? void 0 : event.width
      });
    }), {
      dispose: () => {
        disposable.dispose();
        remove(this._floatingGroups, floatingGroupPanel);
        group.model.location = {
          type: 'grid'
        };
        this.updateWatermark();
      }
    });
    this._floatingGroups.push(floatingGroupPanel);
    group.model.location = {
      type: 'floating'
    };
    if (!(options === null || options === void 0 ? void 0 : options.skipActiveGroup)) {
      this.doSetGroupAndPanelActive(group);
    }
    this.updateWatermark();
  }
  orthogonalize(position) {
    switch (position) {
      case 'top':
      case 'bottom':
        if (this.gridview.orientation === Orientation.HORIZONTAL) {
          // we need to add to a vertical splitview but the current root is a horizontal splitview.
          // insert a vertical splitview at the root level and add the existing view as a child
          this.gridview.insertOrthogonalSplitviewAtRoot();
        }
        break;
      case 'left':
      case 'right':
        if (this.gridview.orientation === Orientation.VERTICAL) {
          // we need to add to a horizontal splitview but the current root is a vertical splitview.
          // insert a horiziontal splitview at the root level and add the existing view as a child
          this.gridview.insertOrthogonalSplitviewAtRoot();
        }
        break;
      default:
        break;
    }
    switch (position) {
      case 'top':
      case 'left':
      case 'center':
        return this.createGroupAtLocation([0]);
      // insert into first position
      case 'bottom':
      case 'right':
        return this.createGroupAtLocation([this.gridview.length]);
      // insert into last position
      default:
        throw new Error(`unsupported position ${position}`);
    }
  }
  updateOptions(options) {
    var _a, _b, _c, _d;
    super.updateOptions(options);
    if ('floatingGroupBounds' in options) {
      for (const group of this._floatingGroups) {
        switch (options.floatingGroupBounds) {
          case 'boundedWithinViewport':
            group.overlay.minimumInViewportHeight = undefined;
            group.overlay.minimumInViewportWidth = undefined;
            break;
          case undefined:
            group.overlay.minimumInViewportHeight = DEFAULT_FLOATING_GROUP_OVERFLOW_SIZE;
            group.overlay.minimumInViewportWidth = DEFAULT_FLOATING_GROUP_OVERFLOW_SIZE;
            break;
          default:
            group.overlay.minimumInViewportHeight = (_a = options.floatingGroupBounds) === null || _a === void 0 ? void 0 : _a.minimumHeightWithinViewport;
            group.overlay.minimumInViewportWidth = (_b = options.floatingGroupBounds) === null || _b === void 0 ? void 0 : _b.minimumWidthWithinViewport;
        }
        group.overlay.setBounds();
      }
    }
    if ('rootOverlayModel' in options) {
      this._rootDropTarget.setOverlayModel((_c = options.rootOverlayModel) !== null && _c !== void 0 ? _c : DEFAULT_ROOT_OVERLAY_MODEL);
    }
    if ('gap' in options) {
      this.gridview.margin = (_d = options.gap) !== null && _d !== void 0 ? _d : 0;
    }
    this._options = Object.assign(Object.assign({}, this.options), options);
    this.layout(this.gridview.width, this.gridview.height, true);
  }
  layout(width, height, forceResize) {
    super.layout(width, height, forceResize);
    if (this._floatingGroups) {
      for (const floating of this._floatingGroups) {
        // ensure floting groups stay within visible boundaries
        floating.overlay.setBounds();
      }
    }
  }
  focus() {
    var _a;
    (_a = this.activeGroup) === null || _a === void 0 ? void 0 : _a.focus();
  }
  getGroupPanel(id) {
    return this.panels.find(panel => panel.id === id);
  }
  setActivePanel(panel) {
    panel.group.model.openPanel(panel);
    this.doSetGroupAndPanelActive(panel.group);
  }
  moveToNext(options = {}) {
    var _a;
    if (!options.group) {
      if (!this.activeGroup) {
        return;
      }
      options.group = this.activeGroup;
    }
    if (options.includePanel && options.group) {
      if (options.group.activePanel !== options.group.panels[options.group.panels.length - 1]) {
        options.group.model.moveToNext({
          suppressRoll: true
        });
        return;
      }
    }
    const location = getGridLocation(options.group.element);
    const next = (_a = this.gridview.next(location)) === null || _a === void 0 ? void 0 : _a.view;
    this.doSetGroupAndPanelActive(next);
  }
  moveToPrevious(options = {}) {
    var _a;
    if (!options.group) {
      if (!this.activeGroup) {
        return;
      }
      options.group = this.activeGroup;
    }
    if (options.includePanel && options.group) {
      if (options.group.activePanel !== options.group.panels[0]) {
        options.group.model.moveToPrevious({
          suppressRoll: true
        });
        return;
      }
    }
    const location = getGridLocation(options.group.element);
    const next = (_a = this.gridview.previous(location)) === null || _a === void 0 ? void 0 : _a.view;
    if (next) {
      this.doSetGroupAndPanelActive(next);
    }
  }
  /**
   * Serialize the current state of the layout
   *
   * @returns A JSON respresentation of the layout
   */
  toJSON() {
    var _a;
    const data = this.gridview.serialize();
    const panels = this.panels.reduce((collection, panel) => {
      collection[panel.id] = panel.toJSON();
      return collection;
    }, {});
    const floats = this._floatingGroups.map(group => {
      return {
        data: group.group.toJSON(),
        position: group.overlay.toJSON()
      };
    });
    const popoutGroups = this._popoutGroups.map(group => {
      return {
        data: group.popoutGroup.toJSON(),
        gridReferenceGroup: group.referenceGroup,
        position: group.window.dimensions()
      };
    });
    const result = {
      grid: data,
      panels,
      activeGroup: (_a = this.activeGroup) === null || _a === void 0 ? void 0 : _a.id
    };
    if (floats.length > 0) {
      result.floatingGroups = floats;
    }
    if (popoutGroups.length > 0) {
      result.popoutGroups = popoutGroups;
    }
    return result;
  }
  fromJSON(data) {
    var _a, _b, _c;
    this.clear();
    if (typeof data !== 'object' || data === null) {
      throw new Error('serialized layout must be a non-null object');
    }
    const {
      grid,
      panels,
      activeGroup
    } = data;
    if (grid.root.type !== 'branch' || !Array.isArray(grid.root.data)) {
      throw new Error('root must be of type branch');
    }
    try {
      // take note of the existing dimensions
      const width = this.width;
      const height = this.height;
      const createGroupFromSerializedState = data => {
        const {
          id,
          locked,
          hideHeader,
          views,
          activeView
        } = data;
        if (typeof id !== 'string') {
          throw new Error('group id must be of type string');
        }
        const group = this.createGroup({
          id,
          locked: !!locked,
          hideHeader: !!hideHeader
        });
        const createdPanels = [];
        for (const child of views) {
          /**
           * Run the deserializer step seperately since this may fail to due corrupted external state.
           * In running this section first we avoid firing lots of 'add' events in the event of a failure
           * due to a corruption of input data.
           */
          const panel = this._deserializer.fromJSON(panels[child], group);
          createdPanels.push(panel);
        }
        this._onDidAddGroup.fire(group);
        for (let i = 0; i < views.length; i++) {
          const panel = createdPanels[i];
          const isActive = typeof activeView === 'string' && activeView === panel.id;
          group.model.openPanel(panel, {
            skipSetActive: !isActive,
            skipSetGroupActive: true
          });
        }
        if (!group.activePanel && group.panels.length > 0) {
          group.model.openPanel(group.panels[group.panels.length - 1], {
            skipSetGroupActive: true
          });
        }
        return group;
      };
      this.gridview.deserialize(grid, {
        fromJSON: node => {
          return createGroupFromSerializedState(node.data);
        }
      });
      this.layout(width, height, true);
      const serializedFloatingGroups = (_a = data.floatingGroups) !== null && _a !== void 0 ? _a : [];
      for (const serializedFloatingGroup of serializedFloatingGroups) {
        const {
          data,
          position
        } = serializedFloatingGroup;
        const group = createGroupFromSerializedState(data);
        this.addFloatingGroup(group, {
          position: position,
          width: position.width,
          height: position.height,
          skipRemoveGroup: true,
          inDragMode: false
        });
      }
      const serializedPopoutGroups = (_b = data.popoutGroups) !== null && _b !== void 0 ? _b : [];
      for (const serializedPopoutGroup of serializedPopoutGroups) {
        const {
          data,
          position,
          gridReferenceGroup
        } = serializedPopoutGroup;
        const group = createGroupFromSerializedState(data);
        this.addPopoutGroup((_c = gridReferenceGroup ? this.getPanel(gridReferenceGroup) : undefined) !== null && _c !== void 0 ? _c : group, {
          skipRemoveGroup: true,
          position: position !== null && position !== void 0 ? position : undefined,
          overridePopoutGroup: gridReferenceGroup ? group : undefined
        });
      }
      for (const floatingGroup of this._floatingGroups) {
        floatingGroup.overlay.setBounds();
      }
      if (typeof activeGroup === 'string') {
        const panel = this.getPanel(activeGroup);
        if (panel) {
          this.doSetGroupAndPanelActive(panel);
        }
      }
    } catch (err) {
      /**
       * Takes all the successfully created groups and remove all of their panels.
       */
      for (const group of this.groups) {
        for (const panel of group.panels) {
          this.removePanel(panel, {
            removeEmptyGroup: false,
            skipDispose: false
          });
        }
      }
      /**
       * To remove a group we cannot call this.removeGroup(...) since this makes assumptions about
       * the underlying HTMLElement existing in the Gridview.
       */
      for (const group of this.groups) {
        group.dispose();
        this._groups.delete(group.id);
        this._onDidRemoveGroup.fire(group);
      }
      // iterate over a reassigned array since original array will be modified
      for (const floatingGroup of [...this._floatingGroups]) {
        floatingGroup.dispose();
      }
      // fires clean-up events and clears the underlying HTML gridview.
      this.clear();
      /**
       * even though we have cleaned-up we still want to inform the caller of their error
       * and we'll do this through re-throwing the original error since afterall you would
       * expect trying to load a corrupted layout to result in an error and not silently fail...
       */
      throw err;
    }
    this.updateWatermark();
    this._onDidLayoutFromJSON.fire();
  }
  clear() {
    const groups = Array.from(this._groups.values()).map(_ => _.value);
    const hasActiveGroup = !!this.activeGroup;
    for (const group of groups) {
      // remove the group will automatically remove the panels
      this.removeGroup(group, {
        skipActive: true
      });
    }
    if (hasActiveGroup) {
      this.doSetGroupAndPanelActive(undefined);
    }
    this.gridview.clear();
  }
  closeAllGroups() {
    for (const entry of this._groups.entries()) {
      const [_, group] = entry;
      group.value.model.closeAllPanels();
    }
  }
  addPanel(options) {
    var _a, _b;
    if (this.panels.find(_ => _.id === options.id)) {
      throw new Error(`panel with id ${options.id} already exists`);
    }
    let referenceGroup;
    if (options.position && options.floating) {
      throw new Error('you can only provide one of: position, floating as arguments to .addPanel(...)');
    }
    const initial = {
      width: options.initialWidth,
      height: options.initialHeight
    };
    if (options.position) {
      if (isPanelOptionsWithPanel(options.position)) {
        const referencePanel = typeof options.position.referencePanel === 'string' ? this.getGroupPanel(options.position.referencePanel) : options.position.referencePanel;
        if (!referencePanel) {
          throw new Error(`referencePanel '${options.position.referencePanel}' does not exist`);
        }
        referenceGroup = this.findGroup(referencePanel);
      } else if (isPanelOptionsWithGroup(options.position)) {
        referenceGroup = typeof options.position.referenceGroup === 'string' ? (_a = this._groups.get(options.position.referenceGroup)) === null || _a === void 0 ? void 0 : _a.value : options.position.referenceGroup;
        if (!referenceGroup) {
          throw new Error(`referenceGroup '${options.position.referenceGroup}' does not exist`);
        }
      } else {
        const group = this.orthogonalize(directionToPosition(options.position.direction));
        const panel = this.createPanel(options, group);
        group.model.openPanel(panel, {
          skipSetActive: options.inactive,
          skipSetGroupActive: options.inactive
        });
        if (!options.inactive) {
          this.doSetGroupAndPanelActive(group);
        }
        group.api.setSize({
          height: initial === null || initial === void 0 ? void 0 : initial.height,
          width: initial === null || initial === void 0 ? void 0 : initial.width
        });
        return panel;
      }
    } else {
      referenceGroup = this.activeGroup;
    }
    let panel;
    if (referenceGroup) {
      const target = toTarget(((_b = options.position) === null || _b === void 0 ? void 0 : _b.direction) || 'within');
      if (options.floating) {
        const group = this.createGroup();
        this._onDidAddGroup.fire(group);
        const floatingGroupOptions = typeof options.floating === 'object' && options.floating !== null ? options.floating : {};
        this.addFloatingGroup(group, Object.assign(Object.assign({}, floatingGroupOptions), {
          inDragMode: false,
          skipRemoveGroup: true,
          skipActiveGroup: true
        }));
        panel = this.createPanel(options, group);
        group.model.openPanel(panel, {
          skipSetActive: options.inactive,
          skipSetGroupActive: options.inactive
        });
      } else if (referenceGroup.api.location.type === 'floating' || target === 'center') {
        panel = this.createPanel(options, referenceGroup);
        referenceGroup.model.openPanel(panel, {
          skipSetActive: options.inactive,
          skipSetGroupActive: options.inactive
        });
        referenceGroup.api.setSize({
          width: initial === null || initial === void 0 ? void 0 : initial.width,
          height: initial === null || initial === void 0 ? void 0 : initial.height
        });
        if (!options.inactive) {
          this.doSetGroupAndPanelActive(referenceGroup);
        }
      } else {
        const location = getGridLocation(referenceGroup.element);
        const relativeLocation = getRelativeLocation(this.gridview.orientation, location, target);
        const group = this.createGroupAtLocation(relativeLocation, this.orientationAtLocation(relativeLocation) === Orientation.VERTICAL ? initial === null || initial === void 0 ? void 0 : initial.height : initial === null || initial === void 0 ? void 0 : initial.width);
        panel = this.createPanel(options, group);
        group.model.openPanel(panel, {
          skipSetActive: options.inactive,
          skipSetGroupActive: options.inactive
        });
        if (!options.inactive) {
          this.doSetGroupAndPanelActive(group);
        }
      }
    } else if (options.floating) {
      const group = this.createGroup();
      this._onDidAddGroup.fire(group);
      const coordinates = typeof options.floating === 'object' && options.floating !== null ? options.floating : {};
      this.addFloatingGroup(group, Object.assign(Object.assign({}, coordinates), {
        inDragMode: false,
        skipRemoveGroup: true,
        skipActiveGroup: true
      }));
      panel = this.createPanel(options, group);
      group.model.openPanel(panel, {
        skipSetActive: options.inactive,
        skipSetGroupActive: options.inactive
      });
    } else {
      const group = this.createGroupAtLocation([0], this.gridview.orientation === Orientation.VERTICAL ? initial === null || initial === void 0 ? void 0 : initial.height : initial === null || initial === void 0 ? void 0 : initial.width);
      panel = this.createPanel(options, group);
      group.model.openPanel(panel, {
        skipSetActive: options.inactive,
        skipSetGroupActive: options.inactive
      });
      if (!options.inactive) {
        this.doSetGroupAndPanelActive(group);
      }
    }
    return panel;
  }
  removePanel(panel, options = {
    removeEmptyGroup: true,
    skipDispose: false
  }) {
    const group = panel.group;
    if (!group) {
      throw new Error(`cannot remove panel ${panel.id}. it's missing a group.`);
    }
    group.model.removePanel(panel, {
      skipSetActiveGroup: options.skipSetActiveGroup
    });
    if (!options.skipDispose) {
      panel.group.model.renderContainer.detatch(panel);
      panel.dispose();
    }
    if (group.size === 0 && options.removeEmptyGroup) {
      this.removeGroup(group, {
        skipActive: options.skipSetActiveGroup
      });
    }
  }
  createWatermarkComponent() {
    if (this.options.createWatermarkComponent) {
      return this.options.createWatermarkComponent();
    }
    return new Watermark();
  }
  updateWatermark() {
    var _a, _b;
    if (this.groups.filter(x => x.api.location.type === 'grid' && x.api.isVisible).length === 0) {
      if (!this.watermark) {
        this.watermark = this.createWatermarkComponent();
        this.watermark.init({
          containerApi: new DockviewApi(this)
        });
        const watermarkContainer = document.createElement('div');
        watermarkContainer.className = 'dv-watermark-container';
        addTestId(watermarkContainer, 'watermark-component');
        watermarkContainer.appendChild(this.watermark.element);
        this.gridview.element.appendChild(watermarkContainer);
      }
    } else if (this.watermark) {
      this.watermark.element.parentElement.remove();
      (_b = (_a = this.watermark).dispose) === null || _b === void 0 ? void 0 : _b.call(_a);
      this.watermark = null;
    }
  }
  addGroup(options) {
    var _a;
    if (options) {
      let referenceGroup;
      if (isGroupOptionsWithPanel(options)) {
        const referencePanel = typeof options.referencePanel === 'string' ? this.panels.find(panel => panel.id === options.referencePanel) : options.referencePanel;
        if (!referencePanel) {
          throw new Error(`reference panel ${options.referencePanel} does not exist`);
        }
        referenceGroup = this.findGroup(referencePanel);
        if (!referenceGroup) {
          throw new Error(`reference group for reference panel ${options.referencePanel} does not exist`);
        }
      } else if (isGroupOptionsWithGroup(options)) {
        referenceGroup = typeof options.referenceGroup === 'string' ? (_a = this._groups.get(options.referenceGroup)) === null || _a === void 0 ? void 0 : _a.value : options.referenceGroup;
        if (!referenceGroup) {
          throw new Error(`reference group ${options.referenceGroup} does not exist`);
        }
      } else {
        const group = this.orthogonalize(directionToPosition(options.direction));
        if (!options.skipSetActive) {
          this.doSetGroupAndPanelActive(group);
        }
        return group;
      }
      const target = toTarget(options.direction || 'within');
      const location = getGridLocation(referenceGroup.element);
      const relativeLocation = getRelativeLocation(this.gridview.orientation, location, target);
      const group = this.createGroup(options);
      const size = this.getLocationOrientation(relativeLocation) === Orientation.VERTICAL ? options.initialHeight : options.initialWidth;
      this.doAddGroup(group, relativeLocation, size);
      if (!options.skipSetActive) {
        this.doSetGroupAndPanelActive(group);
      }
      return group;
    } else {
      const group = this.createGroup(options);
      this.doAddGroup(group);
      this.doSetGroupAndPanelActive(group);
      return group;
    }
  }
  getLocationOrientation(location) {
    return location.length % 2 == 0 && this.gridview.orientation === Orientation.HORIZONTAL ? Orientation.HORIZONTAL : Orientation.VERTICAL;
  }
  removeGroup(group, options) {
    this.doRemoveGroup(group, options);
  }
  doRemoveGroup(group, options) {
    var _a;
    const panels = [...group.panels]; // reassign since group panels will mutate
    if (!(options === null || options === void 0 ? void 0 : options.skipDispose)) {
      for (const panel of panels) {
        this.removePanel(panel, {
          removeEmptyGroup: false,
          skipDispose: (_a = options === null || options === void 0 ? void 0 : options.skipDispose) !== null && _a !== void 0 ? _a : false
        });
      }
    }
    const activePanel = this.activePanel;
    if (group.api.location.type === 'floating') {
      const floatingGroup = this._floatingGroups.find(_ => _.group === group);
      if (floatingGroup) {
        if (!(options === null || options === void 0 ? void 0 : options.skipDispose)) {
          floatingGroup.group.dispose();
          this._groups.delete(group.id);
          this._onDidRemoveGroup.fire(group);
        }
        remove(this._floatingGroups, floatingGroup);
        floatingGroup.dispose();
        if (!(options === null || options === void 0 ? void 0 : options.skipActive) && this._activeGroup === group) {
          const groups = Array.from(this._groups.values());
          this.doSetGroupAndPanelActive(groups.length > 0 ? groups[0].value : undefined);
        }
        return floatingGroup.group;
      }
      throw new Error('failed to find floating group');
    }
    if (group.api.location.type === 'popout') {
      const selectedGroup = this._popoutGroups.find(_ => _.popoutGroup === group);
      if (selectedGroup) {
        if (!(options === null || options === void 0 ? void 0 : options.skipDispose)) {
          if (!(options === null || options === void 0 ? void 0 : options.skipPopoutAssociated)) {
            const refGroup = selectedGroup.referenceGroup ? this.getPanel(selectedGroup.referenceGroup) : undefined;
            if (refGroup) {
              this.removeGroup(refGroup);
            }
          }
          selectedGroup.popoutGroup.dispose();
          this._groups.delete(group.id);
          this._onDidRemoveGroup.fire(group);
        }
        remove(this._popoutGroups, selectedGroup);
        const removedGroup = selectedGroup.disposable.dispose();
        if (!(options === null || options === void 0 ? void 0 : options.skipPopoutReturn) && removedGroup) {
          this.doAddGroup(removedGroup, [0]);
          this.doSetGroupAndPanelActive(removedGroup);
        }
        if (!(options === null || options === void 0 ? void 0 : options.skipActive) && this._activeGroup === group) {
          const groups = Array.from(this._groups.values());
          this.doSetGroupAndPanelActive(groups.length > 0 ? groups[0].value : undefined);
        }
        this.updateWatermark();
        return selectedGroup.popoutGroup;
      }
      throw new Error('failed to find popout group');
    }
    const re = super.doRemoveGroup(group, options);
    if (!(options === null || options === void 0 ? void 0 : options.skipActive)) {
      if (this.activePanel !== activePanel) {
        this._onDidActivePanelChange.fire(this.activePanel);
      }
    }
    return re;
  }
  movingLock(func) {
    const isMoving = this._moving;
    try {
      this._moving = true;
      return func();
    } finally {
      this._moving = isMoving;
    }
  }
  moveGroupOrPanel(options) {
    var _a;
    const destinationGroup = options.to.group;
    const sourceGroupId = options.from.groupId;
    const sourceItemId = options.from.panelId;
    const destinationTarget = options.to.position;
    const destinationIndex = options.to.index;
    const sourceGroup = sourceGroupId ? (_a = this._groups.get(sourceGroupId)) === null || _a === void 0 ? void 0 : _a.value : undefined;
    if (!sourceGroup) {
      throw new Error(`Failed to find group id ${sourceGroupId}`);
    }
    if (sourceItemId === undefined) {
      /**
       * Moving an entire group into another group
       */
      this.moveGroup({
        from: {
          group: sourceGroup
        },
        to: {
          group: destinationGroup,
          position: destinationTarget
        }
      });
      return;
    }
    if (!destinationTarget || destinationTarget === 'center') {
      /**
       * Dropping a panel within another group
       */
      const removedPanel = this.movingLock(() => sourceGroup.model.removePanel(sourceItemId, {
        skipSetActive: false,
        skipSetActiveGroup: true
      }));
      if (!removedPanel) {
        throw new Error(`No panel with id ${sourceItemId}`);
      }
      if (sourceGroup.model.size === 0) {
        // remove the group and do not set a new group as active
        this.doRemoveGroup(sourceGroup, {
          skipActive: true
        });
      }
      this.movingLock(() => destinationGroup.model.openPanel(removedPanel, {
        index: destinationIndex,
        skipSetGroupActive: true
      }));
      this.doSetGroupAndPanelActive(destinationGroup);
      this._onDidMovePanel.fire({
        panel: removedPanel,
        from: sourceGroup
      });
    } else {
      /**
       * Dropping a panel to the extremities of a group which will place that panel
       * into an adjacent group
       */
      const referenceLocation = getGridLocation(destinationGroup.element);
      const targetLocation = getRelativeLocation(this.gridview.orientation, referenceLocation, destinationTarget);
      if (sourceGroup.size < 2) {
        /**
         * If we are moving from a group which only has one panel left we will consider
         * moving the group itself rather than moving the panel into a newly created group
         */
        const [targetParentLocation, to] = tail(targetLocation);
        if (sourceGroup.api.location.type === 'grid') {
          const sourceLocation = getGridLocation(sourceGroup.element);
          const [sourceParentLocation, from] = tail(sourceLocation);
          if (sequenceEquals(sourceParentLocation, targetParentLocation)) {
            // special case when 'swapping' two views within same grid location
            // if a group has one tab - we are essentially moving the 'group'
            // which is equivalent to swapping two views in this case
            this.gridview.moveView(sourceParentLocation, from, to);
            this._onDidMovePanel.fire({
              panel: this.getGroupPanel(sourceItemId),
              from: sourceGroup
            });
            return;
          }
        }
        if (sourceGroup.api.location.type === 'popout') {
          /**
           * the source group is a popout group with a single panel
           *
           * 1. remove the panel from the group without triggering any events
           * 2. remove the popout group
           * 3. create a new group at the requested location and add that panel
           */
          const popoutGroup = this._popoutGroups.find(group => group.popoutGroup === sourceGroup);
          const removedPanel = this.movingLock(() => popoutGroup.popoutGroup.model.removePanel(popoutGroup.popoutGroup.panels[0], {
            skipSetActive: true,
            skipSetActiveGroup: true
          }));
          this.doRemoveGroup(sourceGroup, {
            skipActive: true
          });
          const newGroup = this.createGroupAtLocation(targetLocation);
          this.movingLock(() => newGroup.model.openPanel(removedPanel, {
            skipSetActive: true
          }));
          this.doSetGroupAndPanelActive(newGroup);
          this._onDidMovePanel.fire({
            panel: this.getGroupPanel(sourceItemId),
            from: sourceGroup
          });
          return;
        }
        // source group will become empty so delete the group
        const targetGroup = this.movingLock(() => this.doRemoveGroup(sourceGroup, {
          skipActive: true,
          skipDispose: true
        }));
        // after deleting the group we need to re-evaulate the ref location
        const updatedReferenceLocation = getGridLocation(destinationGroup.element);
        const location = getRelativeLocation(this.gridview.orientation, updatedReferenceLocation, destinationTarget);
        this.movingLock(() => this.doAddGroup(targetGroup, location));
        this.doSetGroupAndPanelActive(targetGroup);
        this._onDidMovePanel.fire({
          panel: this.getGroupPanel(sourceItemId),
          from: sourceGroup
        });
      } else {
        /**
         * The group we are removing from has many panels, we need to remove the panels we are moving,
         * create a new group, add the panels to that new group and add the new group in an appropiate position
         */
        const removedPanel = this.movingLock(() => sourceGroup.model.removePanel(sourceItemId, {
          skipSetActive: false,
          skipSetActiveGroup: true
        }));
        if (!removedPanel) {
          throw new Error(`No panel with id ${sourceItemId}`);
        }
        const dropLocation = getRelativeLocation(this.gridview.orientation, referenceLocation, destinationTarget);
        const group = this.createGroupAtLocation(dropLocation);
        this.movingLock(() => group.model.openPanel(removedPanel, {
          skipSetGroupActive: true
        }));
        this.doSetGroupAndPanelActive(group);
        this._onDidMovePanel.fire({
          panel: removedPanel,
          from: sourceGroup
        });
      }
    }
  }
  moveGroup(options) {
    const from = options.from.group;
    const to = options.to.group;
    const target = options.to.position;
    if (target === 'center') {
      const activePanel = from.activePanel;
      const panels = this.movingLock(() => [...from.panels].map(p => from.model.removePanel(p.id, {
        skipSetActive: true
      })));
      if ((from === null || from === void 0 ? void 0 : from.model.size) === 0) {
        this.doRemoveGroup(from, {
          skipActive: true
        });
      }
      this.movingLock(() => {
        for (const panel of panels) {
          to.model.openPanel(panel, {
            skipSetActive: panel !== activePanel,
            skipSetGroupActive: true
          });
        }
      });
      this.doSetGroupAndPanelActive(to);
    } else {
      switch (from.api.location.type) {
        case 'grid':
          this.gridview.removeView(getGridLocation(from.element));
          break;
        case 'floating':
          {
            const selectedFloatingGroup = this._floatingGroups.find(x => x.group === from);
            if (!selectedFloatingGroup) {
              throw new Error('failed to find floating group');
            }
            selectedFloatingGroup.dispose();
            break;
          }
        case 'popout':
          {
            const selectedPopoutGroup = this._popoutGroups.find(x => x.popoutGroup === from);
            if (!selectedPopoutGroup) {
              throw new Error('failed to find popout group');
            }
            selectedPopoutGroup.disposable.dispose();
          }
      }
      const referenceLocation = getGridLocation(to.element);
      const dropLocation = getRelativeLocation(this.gridview.orientation, referenceLocation, target);
      let size;
      switch (this.gridview.orientation) {
        case Orientation.VERTICAL:
          size = referenceLocation.length % 2 == 0 ? from.api.width : from.api.height;
          break;
        case Orientation.HORIZONTAL:
          size = referenceLocation.length % 2 == 0 ? from.api.height : from.api.width;
          break;
      }
      this.gridview.addView(from, size, dropLocation);
    }
    from.panels.forEach(panel => {
      this._onDidMovePanel.fire({
        panel,
        from
      });
    });
  }
  doSetGroupActive(group) {
    super.doSetGroupActive(group);
    const activePanel = this.activePanel;
    if (!this._moving && activePanel !== this._onDidActivePanelChange.value) {
      this._onDidActivePanelChange.fire(activePanel);
    }
  }
  doSetGroupAndPanelActive(group) {
    super.doSetGroupActive(group);
    const activePanel = this.activePanel;
    if (group && this.hasMaximizedGroup() && !this.isMaximizedGroup(group)) {
      this.exitMaximizedGroup();
    }
    if (!this._moving && activePanel !== this._onDidActivePanelChange.value) {
      this._onDidActivePanelChange.fire(activePanel);
    }
  }
  getNextGroupId() {
    let id = this.nextGroupId.next();
    while (this._groups.has(id)) {
      id = this.nextGroupId.next();
    }
    return id;
  }
  createGroup(options) {
    if (!options) {
      options = {};
    }
    let id = options === null || options === void 0 ? void 0 : options.id;
    if (id && this._groups.has(options.id)) {
      console.warn(`dockview: Duplicate group id ${options === null || options === void 0 ? void 0 : options.id}. reassigning group id to avoid errors`);
      id = undefined;
    }
    if (!id) {
      id = this.nextGroupId.next();
      while (this._groups.has(id)) {
        id = this.nextGroupId.next();
      }
    }
    const view = new DockviewGroupPanel(this, id, options);
    view.init({
      params: {},
      accessor: this
    });
    if (!this._groups.has(view.id)) {
      const disposable = new CompositeDisposable(view.model.onTabDragStart(event => {
        this._onWillDragPanel.fire(event);
      }), view.model.onGroupDragStart(event => {
        this._onWillDragGroup.fire(event);
      }), view.model.onMove(event => {
        const {
          groupId,
          itemId,
          target,
          index
        } = event;
        this.moveGroupOrPanel({
          from: {
            groupId: groupId,
            panelId: itemId
          },
          to: {
            group: view,
            position: target,
            index
          }
        });
      }), view.model.onDidDrop(event => {
        this._onDidDrop.fire(event);
      }), view.model.onWillDrop(event => {
        this._onWillDrop.fire(event);
      }), view.model.onWillShowOverlay(event => {
        if (this.options.disableDnd) {
          event.preventDefault();
          return;
        }
        this._onWillShowOverlay.fire(event);
      }), view.model.onUnhandledDragOverEvent(event => {
        this._onUnhandledDragOverEvent.fire(event);
      }), view.model.onDidAddPanel(event => {
        if (this._moving) {
          return;
        }
        this._onDidAddPanel.fire(event.panel);
      }), view.model.onDidRemovePanel(event => {
        if (this._moving) {
          return;
        }
        this._onDidRemovePanel.fire(event.panel);
      }), view.model.onDidActivePanelChange(event => {
        if (this._moving) {
          return;
        }
        if (event.panel !== this.activePanel) {
          return;
        }
        if (this._onDidActivePanelChange.value !== event.panel) {
          this._onDidActivePanelChange.fire(event.panel);
        }
      }), Event.any(view.model.onDidPanelTitleChange, view.model.onDidPanelParametersChange)(() => {
        this._bufferOnDidLayoutChange.fire();
      }));
      this._groups.set(view.id, {
        value: view,
        disposable
      });
    }
    // TODO: must be called after the above listeners have been setup, not an ideal pattern
    view.initialize();
    return view;
  }
  createPanel(options, group) {
    var _a, _b, _c;
    const contentComponent = options.component;
    const tabComponent = (_a = options.tabComponent) !== null && _a !== void 0 ? _a : this.options.defaultTabComponent;
    const view = new DockviewPanelModel(this, options.id, contentComponent, tabComponent);
    const panel = new DockviewPanel(options.id, contentComponent, tabComponent, this, this._api, group, view, {
      renderer: options.renderer,
      minimumWidth: options.minimumWidth,
      minimumHeight: options.minimumHeight,
      maximumWidth: options.maximumWidth,
      maximumHeight: options.maximumHeight
    });
    panel.init({
      title: (_b = options.title) !== null && _b !== void 0 ? _b : options.id,
      params: (_c = options === null || options === void 0 ? void 0 : options.params) !== null && _c !== void 0 ? _c : {}
    });
    return panel;
  }
  createGroupAtLocation(location, size) {
    const group = this.createGroup();
    this.doAddGroup(group, location, size);
    return group;
  }
  findGroup(panel) {
    var _a;
    return (_a = Array.from(this._groups.values()).find(group => group.value.model.containsPanel(panel))) === null || _a === void 0 ? void 0 : _a.value;
  }
  orientationAtLocation(location) {
    const rootOrientation = this.gridview.orientation;
    return location.length % 2 == 1 ? rootOrientation : orthogonal(rootOrientation);
  }
}