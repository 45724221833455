import { InjectionToken, makeEnvironmentProviders, importProvidersFrom, APP_INITIALIZER } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
const WINDOW = new InjectionToken('Global window object', {
  factory: () => window
});
const MOBILE_RESOLUTION_WIDTH = 960;
const PACKAGES_CONFIG = {};
const packagesConfigLoaded = new BehaviorSubject(false);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deepMerge(obj1, obj2) {
  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (obj1[key] && typeof obj2[key] === 'object') {
        deepMerge(obj1[key], obj2[key]);
      } else {
        obj1[key] = obj2[key];
      }
    }
  }
  return obj1;
}
function apiInitializerFactory(httpClient) {
  return async () => {
    if (PACKAGES_CONFIG.api?.loadConfigJson) {
      const config = await lastValueFrom(httpClient.get(PACKAGES_CONFIG.api.configJsonUrl));
      deepMerge(PACKAGES_CONFIG, config);
      PACKAGES_CONFIG.api.rawJson = config;
      packagesConfigLoaded.next(true);
    } else {
      packagesConfigLoaded.next(true);
    }
  };
}
function provideApi(configFromProvider) {
  const config = {
    appId: 'mship-app',
    frameOrigin: undefined,
    loadConfigJson: true,
    configJsonUrl: 'config.json',
    initializerFactory: apiInitializerFactory,
    initializerFactoryDeps: [HttpClient]
  };
  if (configFromProvider) {
    deepMerge(config, configFromProvider);
  }
  PACKAGES_CONFIG.api = {
    appId: config.appId,
    frameOrigin: config.frameOrigin || window.location.origin.replace(config.appId, 'mship-service-dlp'),
    loadConfigJson: config.loadConfigJson,
    configJsonUrl: config.configJsonUrl
  };
  return makeEnvironmentProviders([provideHttpClient(withInterceptorsFromDi()), importProvidersFrom(BrowserModule, BrowserAnimationsModule), {
    provide: APP_INITIALIZER,
    useFactory: config.initializerFactory,
    multi: true,
    deps: config.initializerFactoryDeps
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { MOBILE_RESOLUTION_WIDTH, PACKAGES_CONFIG, WINDOW, apiInitializerFactory, deepMerge, packagesConfigLoaded, provideApi };
