import { makeEnvironmentProviders } from '@angular/core';
import { provideApi } from '@mship/design-ng/custom/api';
import { provideTheme } from '@mship/design-ng/custom/theme';
import { provideAuthorization } from '@mship/design-ng/custom/authorization';
import { provideAuth } from '@mship/design-ng/custom/auth';
import { provideAppShell } from '@mship/design-ng/custom/app-shell';
import { provideI18n } from '@mship/design-ng/custom/i18n';
import { provideOpenDlpPanel } from '@mship/design-ng/custom/open-dlp-panel';
import { provideServiceWorkerUpdates } from '@mship/design-ng/custom/service-worker-update';
function provideMship(mshipProviderConfig) {
  return makeEnvironmentProviders([provideApi(mshipProviderConfig.api), provideI18n(mshipProviderConfig.i18n), provideAppShell(mshipProviderConfig.appShell), provideAuth(mshipProviderConfig.auth), provideAuthorization(mshipProviderConfig.authorization), provideTheme(mshipProviderConfig.theme), provideOpenDlpPanel(mshipProviderConfig.openDlpPanel), provideServiceWorkerUpdates(mshipProviderConfig.serviceWorkerUpdate)]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { provideMship };
