import { last } from '../../../array';
import { getPanelData } from '../../../dnd/dataTransfer';
import { Droptarget } from '../../../dnd/droptarget';
import { GroupDragHandler } from '../../../dnd/groupDragHandler';
import { addDisposableListener, Emitter } from '../../../events';
import { CompositeDisposable } from '../../../lifecycle';
export class VoidContainer extends CompositeDisposable {
  get element() {
    return this._element;
  }
  constructor(accessor, group) {
    super();
    this.accessor = accessor;
    this.group = group;
    this._onDrop = new Emitter();
    this.onDrop = this._onDrop.event;
    this._onDragStart = new Emitter();
    this.onDragStart = this._onDragStart.event;
    this._element = document.createElement('div');
    this._element.className = 'void-container';
    this._element.tabIndex = 0;
    this._element.draggable = true;
    this.addDisposables(this._onDrop, this._onDragStart, addDisposableListener(this._element, 'pointerdown', () => {
      this.accessor.doSetGroupActive(this.group);
    }));
    const handler = new GroupDragHandler(this._element, accessor, group);
    this.dropTraget = new Droptarget(this._element, {
      acceptedTargetZones: ['center'],
      canDisplayOverlay: (event, position) => {
        var _a;
        const data = getPanelData();
        if (data && this.accessor.id === data.viewId) {
          if (data.panelId === null && data.groupId === this.group.id) {
            // don't allow group move to drop on self
            return false;
          }
          // don't show the overlay if the tab being dragged is the last panel of this group
          return ((_a = last(this.group.panels)) === null || _a === void 0 ? void 0 : _a.id) !== data.panelId;
        }
        return group.model.canDisplayOverlay(event, position, 'header_space');
      }
    });
    this.onWillShowOverlay = this.dropTraget.onWillShowOverlay;
    this.addDisposables(handler, handler.onDragStart(event => {
      this._onDragStart.fire(event);
    }), this.dropTraget.onDrop(event => {
      this._onDrop.fire(event);
    }), this.dropTraget);
  }
}