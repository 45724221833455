<p-card [header]="'serviceDlp.gridStatusDashboard.time' | translate">
  <div class="dlp-grid-status-dashboard-time">
    <p-messages severity="contrast" styleClass="message-no-margin">
      <ng-template pTemplate>
        <div class="dlp-grid-status-dashboard-time__time-display">
          <i class="pi pi-clock"></i>
          {{ time() | date: ('serviceDlp.format.time' | translate) }}
        </div>
      </ng-template>
    </p-messages>
    <p-messages severity="contrast" styleClass="message-no-margin">
      <ng-template pTemplate>
        <div class="dlp-grid-status-dashboard-time__time-display">
          <i class="pi pi-bolt"></i>
          {{ gridTime() | date: ('serviceDlp.format.time' | translate) }}
        </div>
      </ng-template>
    </p-messages>
  </div>
</p-card>
