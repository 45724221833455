import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DockviewComponent, IContentRenderer, IHeaderActionsRenderer, positionToDirection } from 'dockview-core';
import { IframePanelComponent } from './panels/iframe-panel/iframe-panel.component';
import { RightHeaderActionComponent } from './right-header-action/right-header-action.component';
import { LeftHeaderActionComponent } from './left-header-action/left-header-action.component';
import { NotificationPanelComponent } from './panels/notification-panel/notification-panel.component';
import { ITabRenderer } from 'dockview-core/dist/cjs/dockview/types';
import { TabComponent } from './tab/tab.component';
import { WINDOW } from '@mship/design-ng/custom/api';
import { WindowManagerService } from '../shared/window-manager/window-manager.service';
import { GridStatusDashboardPanelComponent } from './panels/grid-status-dashboard-panel/grid-status-dashboard-panel.component';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'dlp-panels',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './panels.component.html',
  styleUrl: './panels.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelsComponent implements AfterViewInit {
  @ViewChild('panels') panels!: ElementRef;

  dragChannel = new BroadcastChannel('mship-drag');

  constructor(
    @Inject(WINDOW) private window: Window,
    private viewContainerRef: ViewContainerRef,
    private windowManagerService: WindowManagerService,
  ) {
    this.initDragEventListener();
  }

  ngAfterViewInit(): void {
    this.attach(this.panels.nativeElement);
  }

  private initDragEventListener() {
    this.dragChannel.addEventListener('message', (event) => {
      if (event.data === 'dragstart') {
        this.setPointerEvents('none');
      } else if (event.data === 'dragend') {
        this.setPointerEvents('auto');
      }
    });
  }

  private attach(parent: HTMLElement): {
    dispose: () => void;
  } {
    const element = document.createElement('div');
    element.className = 'dockview-theme-dark';
    element.style.height = '100%';
    element.style.width = '100%';

    this.window.dockview = new DockviewComponent(element, {
      createComponent: (options): IContentRenderer => {
        let componentToRender: Type<unknown>;
        switch (options.name) {
          case 'NotificationPanelComponent': {
            componentToRender = NotificationPanelComponent;
            break;
          }
          case 'IframePanelComponent': {
            componentToRender = IframePanelComponent;
            break;
          }
          case 'GridStatusDashboardPanelComponent': {
            componentToRender = GridStatusDashboardPanelComponent;
            break;
          }
          default: {
            componentToRender = IframePanelComponent;
            break;
          }
        }
        const panelComponent = this.viewContainerRef.createComponent(componentToRender);
        return <IContentRenderer>panelComponent.instance;
      },
      defaultTabComponent: 'TabComponent',
      createTabComponent: (): ITabRenderer => {
        const tabComponent = this.viewContainerRef.createComponent(TabComponent);
        return tabComponent.instance;
      },
      createLeftHeaderActionComponent: (): IHeaderActionsRenderer => {
        const leftHeaderActionComponent = this.viewContainerRef.createComponent(LeftHeaderActionComponent);
        return leftHeaderActionComponent.instance;
      },
      createRightHeaderActionComponent: (): IHeaderActionsRenderer => {
        const rightHeaderActionComponent = this.viewContainerRef.createComponent(RightHeaderActionComponent);
        return rightHeaderActionComponent.instance;
      },
    });

    parent.appendChild(element);

    this.window.dockview.onDidDrop((event) => {
      const panel = JSON.parse(event.nativeEvent.dataTransfer?.getData('mship-drag') || '{}');
      if (panel.navigationCommands) {
        this.windowManagerService.openPanel(uuid(), panel.label, panel.navigationCommands, {
          position: {
            direction: positionToDirection(event.position),
            referencePanel: event.panel,
            referenceGroup: event.group,
          },
        });
      }
    });

    this.window.dockview.onUnhandledDragOverEvent((event) => {
      event.accept();
    });

    this.window.dockview.onWillDragPanel((event) => {
      event.nativeEvent.dataTransfer?.setData(
        'mship-drag',
        JSON.stringify({
          label: event.panel.params?.['orgTitle'],
          navigationCommands: event.panel.params?.['navigationCommands'],
        }),
      );

      this.dragChannel.postMessage('dragstart');

      event.nativeEvent.target?.addEventListener('dragend', () => {
        this.dragChannel.postMessage('dragend');
      });
    });

    this.window.dispatchEvent(new CustomEvent('dockview-initialized', { detail: this.window.dockview }));

    return {
      dispose: () => {
        this.window.dockview.dispose();
        element.remove();
      },
    };
  }

  private setPointerEvents(state: 'auto' | 'none') {
    const getElementsByTagName = (tag: string): HTMLElement[] => {
      return Array.prototype.slice.call(this.window.document.getElementsByTagName(tag), 0);
    };
    const iframes = [...getElementsByTagName('iframe'), ...getElementsByTagName('webview')];
    for (const iframe of iframes) {
      iframe.style.pointerEvents = state;
    }
  }
}
