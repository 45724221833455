import { Component, DestroyRef, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridStatusDashboardAlarmData } from '../grid-status-dashboard.interface';
import { combineLatest, distinctUntilChanged, filter, map, Observable, tap } from 'rxjs';
import { MessageModule } from '@mship/design-ng/base/message';
import { TranslateModule } from '@ngx-translate/core';
import { Message } from '@mship/design-ng/base/api';
import { I18nService } from '@mship/design-ng/custom/i18n';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MessagesModule } from '@mship/design-ng/base/messages';
import { CardModule } from '@mship/design-ng/base/card';

@Component({
  selector: 'dlp-grid-status-dashboard-alarms',
  standalone: true,
  imports: [CommonModule, MessageModule, TranslateModule, MessagesModule, CardModule],
  templateUrl: './grid-status-dashboard-alarms.component.html',
  styleUrl: './grid-status-dashboard-alarms.component.scss',
})
export class GridStatusDashboardAlarmsComponent implements OnInit {
  @Input({ required: true })
  alarmData$!: Observable<GridStatusDashboardAlarmData | undefined>;
  alarms: WritableSignal<Message[]> = signal([]);

  constructor(
    private destroyRef: DestroyRef,
    private i18nService: I18nService,
  ) {}

  ngOnInit() {
    combineLatest([this.alarmData$, this.i18nService.currentLanguage$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        map(([alarms]) => alarms),
        filter((alarms): alarms is GridStatusDashboardAlarmData => !!alarms),
        tap((alarms) => this.alarms.set(this.getAlerts(alarms))),
      )
      .subscribe();
  }

  getAlerts(alarms: GridStatusDashboardAlarmData) {
    return [
      this.getMessage(alarms.critical, 'error', 'critical'),
      this.getMessage(alarms.high, 'warn', 'high'),
      this.getMessage(alarms.medium, 'warn', 'medium'),
      this.getMessage(alarms.info, 'info', 'info'),
    ];
  }

  getMessage(amount: number, severity: string, name: string): Message {
    return {
      severity: amount > 0 ? severity : 'secondary',
      detail: this.i18nService.instant('serviceDlp.gridStatusDashboard.alarms.' + name, { amount: amount }),
    };
  }
}
