import { Component, DestroyRef, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { tap, timer } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CardModule } from '@mship/design-ng/base/card';
import { MessageModule } from '@mship/design-ng/base/message';
import { MessagesModule } from '@mship/design-ng/base/messages';

@Component({
  selector: 'dlp-grid-status-dashboard-grid-time',
  standalone: true,
  imports: [CommonModule, TranslateModule, CardModule, MessageModule, MessagesModule],
  templateUrl: './grid-status-dashboard-grid-time.component.html',
  styleUrl: './grid-status-dashboard-grid-time.component.scss',
})
export class GridStatusDashboardGridTimeComponent implements OnInit {
  time = signal(new Date());
  gridTime = signal(new Date());

  constructor(private destroyRef: DestroyRef) {}

  ngOnInit() {
    timer(0, 1000)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.setTimes(this.time, this.gridTime)),
      )
      .subscribe();
  }

  setTimes(time: WritableSignal<Date>, gridTime: WritableSignal<Date>) {
    const date = new Date();
    time.set(new Date(date));
    date.setSeconds(date.getSeconds() - 3);
    gridTime.set(date);
  }
}
