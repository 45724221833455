export function tail(arr) {
  if (arr.length === 0) {
    throw new Error('Invalid tail call');
  }
  return [arr.slice(0, arr.length - 1), arr[arr.length - 1]];
}
export function last(arr) {
  return arr.length > 0 ? arr[arr.length - 1] : undefined;
}
export function sequenceEquals(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}
/**
 * Pushes an element to the start of the array, if found.
 */
export function pushToStart(arr, value) {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
    arr.unshift(value);
  }
}
/**
 * Pushes an element to the end of the array, if found.
 */
export function pushToEnd(arr, value) {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
    arr.push(value);
  }
}
export function firstIndex(array, fn) {
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (fn(element)) {
      return i;
    }
  }
  return -1;
}
export function remove(array, value) {
  const index = array.findIndex(t => t === value);
  if (index > -1) {
    array.splice(index, 1);
    return true;
  }
  return false;
}