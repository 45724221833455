import { AfterViewInit, Component, DestroyRef, Input, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule, UIChart } from '@mship/design-ng/base/chart';
import { GridStatusDashboardChartData } from '../grid-status-dashboard.interface';
import { combineLatest, distinctUntilChanged, filter, map, Observable, tap } from 'rxjs';
import { I18nService } from '@mship/design-ng/custom/i18n';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CardModule } from '@mship/design-ng/base/card';
import { TranslateModule } from '@ngx-translate/core';
import { MessagesModule } from '@mship/design-ng/base/messages';
import { Message } from '@mship/design-ng/base/api';

interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor?: string[];
    hoverBackgroundColor?: string[];
  }[];
}

@Component({
  selector: 'dlp-grid-status-dashboard-chart',
  standalone: true,
  imports: [CommonModule, ChartModule, CardModule, TranslateModule, MessagesModule],
  templateUrl: './grid-status-dashboard-chart.component.html',
  styleUrl: './grid-status-dashboard-chart.component.scss',
})
export class GridStatusDashboardChartComponent implements AfterViewInit {
  @Input({ required: true })
  chartData$!: Observable<GridStatusDashboardChartData | undefined>;
  status: WritableSignal<Message[]> = signal([]);

  @ViewChild('chart')
  chart!: UIChart;

  data: ChartData = {
    labels: [],
    datasets: [
      {
        data: [1, 1, 1, 1, 1, 1],
      },
    ],
  };
  options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: () => '',
        },
      },
    },
    animation: false,
  };

  constructor(
    private destroyRef: DestroyRef,
    private i18nService: I18nService,
  ) {}

  ngAfterViewInit() {
    this.updateChart();
    this.updateLabels();
    this.updateMessages();
  }

  updateChart() {
    this.chartData$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((data): data is GridStatusDashboardChartData => !!data),
        distinctUntilChanged(),
        tap((data) => (this.data.datasets[0].backgroundColor = this.createDataColors(data, this.createStatusColor))),
        tap(
          (data) =>
            (this.data.datasets[0].hoverBackgroundColor = this.createDataColors(data, this.createHoverStatusColor)),
        ),
        tap(() => this.chart.reinit()),
      )
      .subscribe();
  }

  updateLabels() {
    this.i18nService.currentLanguage$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => (this.data.labels = this.createLabels())),
        tap(() => this.chart.reinit()),
      )
      .subscribe();
  }

  updateMessages() {
    combineLatest([this.chartData$, this.i18nService.currentLanguage$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        map(([status]) => status),
        filter((status): status is GridStatusDashboardChartData => !!status),
        tap((status) => this.status.set(this.getStatus(status))),
      )
      .subscribe();
  }

  createDataColors(data: GridStatusDashboardChartData, colorFunction: (status: number) => string): string[] {
    return [
      colorFunction(data.congestions),
      colorFunction(data.frequencyStability),
      colorFunction(data.voltageQuality),
      colorFunction(data.operationReserve),
      colorFunction(data.outages),
      colorFunction(data.alarms),
    ];
  }

  createStatusColor(status: number): string {
    const documentStyle = getComputedStyle(document.documentElement);

    if (status > 66) {
      return documentStyle.getPropertyValue('--green-500');
    }
    if (status > 33) {
      return documentStyle.getPropertyValue('--yellow-400');
    }
    return documentStyle.getPropertyValue('--red-600');
  }

  createHoverStatusColor(status: number): string {
    const documentStyle = getComputedStyle(document.documentElement);

    if (status > 66) {
      return documentStyle.getPropertyValue('--green-400');
    }
    if (status > 33) {
      return documentStyle.getPropertyValue('--yellow-300');
    }
    return documentStyle.getPropertyValue('--red-400');
  }

  createLabels(): string[] {
    return [
      this.i18nService.instant('serviceDlp.gridStatusDashboard.status.congestions'),
      this.i18nService.instant('serviceDlp.gridStatusDashboard.status.frequencyStability'),
      this.i18nService.instant('serviceDlp.gridStatusDashboard.status.voltageQuality'),
      this.i18nService.instant('serviceDlp.gridStatusDashboard.status.operationalReserve'),
      this.i18nService.instant('serviceDlp.gridStatusDashboard.status.outages'),
      this.i18nService.instant('serviceDlp.gridStatusDashboard.status.alarms'),
    ];
  }

  private getStatus(status: GridStatusDashboardChartData) {
    return [
      this.getMessage(status.congestions, 'congestions'),
      this.getMessage(status.frequencyStability, 'frequencyStability'),
      this.getMessage(status.voltageQuality, 'voltageQuality'),
      this.getMessage(status.operationReserve, 'operationalReserve'),
      this.getMessage(status.outages, 'outages'),
      this.getMessage(status.alarms, 'alarms'),
    ];
  }

  getMessage(status: number, name: string): Message {
    return {
      severity: this.getSeverity(status),
      detail: this.i18nService.instant('serviceDlp.gridStatusDashboard.status.' + name),
    };
  }

  getSeverity(status: number) {
    if (status > 66) {
      return 'success';
    }
    if (status > 33) {
      return 'warn';
    }
    return 'error';
  }
}
