import { addDisposableListener, Emitter } from '../../../events';
import { CompositeDisposable } from '../../../lifecycle';
import { getPanelData, LocalSelectionTransfer, PanelTransfer } from '../../../dnd/dataTransfer';
import { toggleClass } from '../../../dom';
import { Droptarget } from '../../../dnd/droptarget';
import { DragHandler } from '../../../dnd/abstractDragHandler';
class TabDragHandler extends DragHandler {
  constructor(element, accessor, group, panel) {
    super(element);
    this.accessor = accessor;
    this.group = group;
    this.panel = panel;
    this.panelTransfer = LocalSelectionTransfer.getInstance();
  }
  getData(event) {
    this.panelTransfer.setData([new PanelTransfer(this.accessor.id, this.group.id, this.panel.id)], PanelTransfer.prototype);
    return {
      dispose: () => {
        this.panelTransfer.clearData(PanelTransfer.prototype);
      }
    };
  }
}
export class Tab extends CompositeDisposable {
  get element() {
    return this._element;
  }
  constructor(panel, accessor, group) {
    super();
    this.panel = panel;
    this.accessor = accessor;
    this.group = group;
    this.content = undefined;
    this._onChanged = new Emitter();
    this.onChanged = this._onChanged.event;
    this._onDropped = new Emitter();
    this.onDrop = this._onDropped.event;
    this._onDragStart = new Emitter();
    this.onDragStart = this._onDragStart.event;
    this._element = document.createElement('div');
    this._element.className = 'tab';
    this._element.tabIndex = 0;
    this._element.draggable = true;
    toggleClass(this.element, 'inactive-tab', true);
    const dragHandler = new TabDragHandler(this._element, this.accessor, this.group, this.panel);
    this.dropTarget = new Droptarget(this._element, {
      acceptedTargetZones: ['center'],
      canDisplayOverlay: (event, position) => {
        if (this.group.locked) {
          return false;
        }
        const data = getPanelData();
        if (data && this.accessor.id === data.viewId) {
          if (data.panelId === null && data.groupId === this.group.id) {
            // don't allow group move to drop on self
            return false;
          }
          return this.panel.id !== data.panelId;
        }
        return this.group.model.canDisplayOverlay(event, position, 'tab');
      }
    });
    this.onWillShowOverlay = this.dropTarget.onWillShowOverlay;
    this.addDisposables(this._onChanged, this._onDropped, this._onDragStart, dragHandler.onDragStart(event => {
      this._onDragStart.fire(event);
    }), dragHandler, addDisposableListener(this._element, 'pointerdown', event => {
      if (event.defaultPrevented) {
        return;
      }
      this._onChanged.fire(event);
    }), this.dropTarget.onDrop(event => {
      this._onDropped.fire(event);
    }), this.dropTarget);
  }
  setActive(isActive) {
    toggleClass(this.element, 'active-tab', isActive);
    toggleClass(this.element, 'inactive-tab', !isActive);
  }
  setContent(part) {
    if (this.content) {
      this._element.removeChild(this.content.element);
    }
    this.content = part;
    this._element.appendChild(this.content.element);
  }
  dispose() {
    super.dispose();
  }
}