import { DestroyRef, Injectable } from '@angular/core';
import {
  FavoriteDtoApiModel,
  FavoritesApiService,
  MainMenuApiService,
  MainMenuDtoApiModel,
} from '@mship/service-dlp-api';
import {
  LaunchMenuAppEntry,
  LaunchMenuConfig,
  LaunchMenuLabel,
  LaunchMenuSection,
} from '@mship/design-ng/custom/launch-menu';
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Favorites, NavigationCommandsTemplate } from './launch-menu.interface';
import { Action, AuthorizationService } from '@mship/design-ng/custom/authorization';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class LaunchMenuService {
  static readonly EMPTY_MAIN_MENU: MainMenuDtoApiModel = {
    sections: [],
    apps: [],
  };
  static readonly EMPTY_LAUNCH_MENU: LaunchMenuConfig = {
    menu: {
      recent: false,
      sections: [],
    },
    apps: [],
  };

  private readonly favoritesSection: LaunchMenuSection = {
    labels: [
      {
        language: 'en',
        value: 'Favorites',
      },
      {
        language: 'de',
        value: 'Favoriten',
      },
    ],
    tags: new Set(['favorite']),
  };

  private mainMenu?: MainMenuDtoApiModel;
  private favorites = new BehaviorSubject<Favorites>(new Map());
  private favoritesAllowed = new BehaviorSubject(false);
  private launchMenuConfig = new BehaviorSubject(LaunchMenuService.EMPTY_LAUNCH_MENU);

  get launchMenuConfig$(): Observable<LaunchMenuConfig> {
    return this.launchMenuConfig.asObservable();
  }

  get favoritesAllowed$(): Observable<boolean> {
    return this.favoritesAllowed.asObservable();
  }

  constructor(
    private mainMenuApiService: MainMenuApiService,
    private favoritesApiService: FavoritesApiService,
    private authorizationService: AuthorizationService,
    private destroyRef: DestroyRef,
  ) {
    this.initLaunchMenu();
  }

  toggleFavorite(launchMenuAppEntry: LaunchMenuAppEntry): void {
    const newFavorite = {
      appId: launchMenuAppEntry.appId,
      menuItemId: launchMenuAppEntry.id,
    };
    const favoriteKey = this.getFavoriteKey(newFavorite);
    const favorite = this.favorites.value.get(favoriteKey);

    let request: Observable<Favorites>;
    if (favorite) {
      request = this.favoritesApiService.deleteFavorites(favorite.id).pipe(
        map(() => {
          const favorites = new Map(this.favorites.value);
          favorites.delete(favoriteKey);
          return favorites;
        }),
      );
    } else {
      request = this.favoritesApiService.createFavorite(newFavorite).pipe(
        map((favorite) => {
          const favorites = new Map(this.favorites.value);
          favorites.set(favoriteKey, favorite as Required<FavoriteDtoApiModel>);
          return favorites;
        }),
      );
    }

    request
      .pipe(
        tap((favorites) => {
          this.favorites.next(favorites);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  createNavigationCommandsTemplate(urlString: string): NavigationCommandsTemplate {
    const url = this.parseURL(urlString);
    if (!url) {
      return { path: '' };
    }

    let currentPath = url.pathname;
    const app = this.mainMenu?.apps.find((app) => app.baseUrl && currentPath.startsWith(app.baseUrl));
    if (app?.baseUrl) {
      currentPath = currentPath.replace(app.baseUrl, '');
    } else {
      return { path: url.pathname };
    }

    const menuItem = app.menuItems.find((menuItem) => {
      const menuItemPath = menuItem.navigationCommands.join('');
      return menuItemPath === currentPath;
    });

    return {
      path: currentPath,
      appId: app.id,
      menuItemId: menuItem?.id,
    };
  }

  applyNavigationCommandsTemplate(template: NavigationCommandsTemplate): string[] {
    const { path, appId, menuItemId } = template;
    const app = appId ? this.mainMenu?.apps.find((app) => app.id === appId) : undefined;
    const menuItem = menuItemId && app ? app.menuItems.find((menuItem) => menuItem.id === menuItemId) : undefined;

    if (app && menuItem) {
      return this.sanitizeArray([app.baseUrl, ...menuItem.navigationCommands]);
    } else if (app) {
      return this.sanitizeArray([app.baseUrl, path]);
    } else {
      return [path];
    }
  }

  private initLaunchMenu(): void {
    fromPromise(
      this.authorizationService.check({
        objectId: 'favorites',
        action: Action.EDIT,
      }),
    )
      .pipe(
        filter(Boolean),
        switchMap(() => {
          return this.favoritesApiService.getFavorites();
        }),
        catchError(() => of([] as FavoriteDtoApiModel[])),
        tap((favorites) => {
          this.favoritesAllowed.next(true);
          this.favorites.next(
            new Map(
              (favorites as Required<FavoriteDtoApiModel>[]).map((favorite) => [
                this.getFavoriteKey(favorite),
                favorite,
              ]),
            ),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    const launchMenu$ = this.mainMenuApiService
      .getMainMenu()
      .pipe(catchError(() => of(LaunchMenuService.EMPTY_MAIN_MENU)));

    combineLatest([launchMenu$, this.favorites])
      .pipe(
        tap(([mainMenu, favorites]) => {
          this.mainMenu = mainMenu;
          this.launchMenuConfig.next(this.mapToLaunchMenuConfig(mainMenu, favorites));
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private mapToLaunchMenuConfig(mainMenu: MainMenuDtoApiModel, favorites: Favorites): LaunchMenuConfig {
    const { sections, apps } = mainMenu;
    return {
      menu: {
        recent: false,
        sections: [
          this.favoritesSection,
          ...sections.map((section) => ({
            labels: section.labels as LaunchMenuLabel[],
            tags: new Set(section.tags),
          })),
        ],
      },
      apps: apps.map((app) => ({
        app: app.id,
        entries: app.menuItems.map((menuItem) => ({
          id: menuItem.id,
          appId: app.id,
          labels: menuItem.labels as LaunchMenuLabel[],
          iconClass: menuItem.icon,
          navigationCommands: [app.baseUrl, ...menuItem.navigationCommands].filter((s): s is string => !!s),
          tags: new Set(
            this.sanitizeArray([
              ...menuItem.tags,
              favorites.get(this.getFavoriteKey({ appId: app.id, menuItemId: menuItem.id })) ? 'favorite' : undefined,
            ]),
          ),
          weight: menuItem.weight,
        })),
      })),
    };
  }

  private parseURL(url: string): URL | undefined {
    try {
      return new URL(url);
    } catch (_error) {
      return undefined;
    }
  }

  private sanitizeArray<T>(values: (T | undefined)[]): T[] {
    return values.filter((value): value is T => value !== undefined);
  }

  private getFavoriteKey(favorite: FavoriteDtoApiModel): string {
    return `${favorite.appId}:${favorite.menuItemId}`;
  }
}
