import { Component, DestroyRef, effect, ElementRef, Signal, signal, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WindowManagerService } from '../shared/window-manager/window-manager.service';
import { WindowArrangementDtoApiModel, WindowArrangementsApiService } from '@mship/service-dlp-api';
import { InputTextModule } from '@mship/design-ng/base/inputtext';
import { Button } from '@mship/design-ng/base/button';
import { OverlayPanel, OverlayPanelModule } from '@mship/design-ng/base/overlaypanel';
import { FormArray, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { map, startWith, tap } from 'rxjs';
import { ArrangementsFormGroup, WindowArrangementFormGroup } from './window-menu.interface';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { I18nService } from '@mship/design-ng/custom/i18n';

@Component({
  selector: 'dlp-window-menu',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    OverlayPanelModule,
    InputTextModule,
    Button,
    OverlayPanelModule,
    FormsModule,
    ReactiveFormsModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './window-menu.component.html',
  styleUrl: './window-menu.component.scss',
})
export class WindowMenuComponent {
  @ViewChild('windowMenu') windowMenu!: OverlayPanel;
  @ViewChildren('windowArrangement') arrangementsElements!: ElementRef<HTMLLIElement>[];

  arrangementsForm: ArrangementsFormGroup = this.fb.group({
    searchText: this.fb.control<string | undefined>(undefined),
    arrangements: this.fb.array<WindowArrangementFormGroup>([]),
  });
  filteredArrangements = this.getFilteredWindowArrangements(this.arrangementsForm);
  edit = signal(false);

  private originalArrangements: WindowArrangementDtoApiModel[] = [];

  constructor(
    private windowManagerService: WindowManagerService,
    private windowArrangementsApiService: WindowArrangementsApiService,
    private fb: NonNullableFormBuilder,
    private i18nService: I18nService,
    private destroyRef: DestroyRef,
  ) {
    this.initFormEffects();
    this.initWindowArrangements();
  }

  onAdd(): void {
    this.edit.set(true);
    this.addNewWindowArrangement();
  }

  onEdit(): void {
    this.edit.set(true);
  }

  onCancel(): void {
    this.edit.set(false);
    this.setWindowArrangements(this.originalArrangements);
  }

  onSave(): void {
    if (this.arrangementsForm.invalid) {
      return;
    }

    const arrangements = this.getWindowArrangements(this.arrangementsForm);
    this.windowArrangementsApiService
      .persistWindowArrangements(arrangements)
      .pipe(
        tap(() => {
          this.edit.set(false);
          this.originalArrangements = arrangements;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  async onArrangementReplace(windowArrangement?: string): Promise<void> {
    if (!(await this.checkIfWindowManagementPermissionGranted())) {
      return;
    }

    if (windowArrangement) {
      this.windowManagerService.replaceWindows(windowArrangement);
      this.windowMenu.hide();
    }
  }

  async onArrangementExtend(windowArrangement?: string): Promise<void> {
    if (!(await this.checkIfWindowManagementPermissionGranted())) {
      return;
    }

    if (windowArrangement) {
      this.windowManagerService.extendWindows(windowArrangement);
      this.windowMenu.hide();
    }
  }

  onArrangementDelete(index: number): void {
    this.arrangementsForm.controls.arrangements.removeAt(index);
  }

  onArrangementDrop(event: CdkDragDrop<WindowArrangementFormGroup[]>): void {
    const { previousIndex, currentIndex } = event;
    this.moveWindowArrangements(this.arrangementsForm.controls.arrangements, previousIndex, currentIndex);
  }

  private getWindowArrangements(arrangementsForm: ArrangementsFormGroup): WindowArrangementDtoApiModel[] {
    if (arrangementsForm.invalid) {
      return [];
    }

    return (
      arrangementsForm.value.arrangements?.map(({ id, label, windowArrangement }, index) => ({
        id,
        label: label as string,
        windowArrangement: windowArrangement as string,
        seqNo: index,
      })) ?? []
    );
  }

  private getFilteredWindowArrangements(arrangementsForm: ArrangementsFormGroup): Signal<WindowArrangementFormGroup[]> {
    return toSignal(
      arrangementsForm.valueChanges.pipe(
        startWith(arrangementsForm.value),
        map(({ searchText }) => {
          return arrangementsForm.controls.arrangements.controls.filter((arrangement) => {
            const label = arrangement.value.label?.toLowerCase();
            return !searchText || !label || label.includes(searchText.toLowerCase());
          });
        }),
      ),
      { initialValue: [] },
    );
  }

  private async addNewWindowArrangement(): Promise<void> {
    const windowArrangement = await this.windowManagerService.getCurrentWindowsArrangement();
    if (windowArrangement) {
      this.arrangementsForm.controls.arrangements.push(this.buildWindowArrangementFormGroup({ windowArrangement }));
    }
  }

  private setWindowArrangements(arrangements: WindowArrangementDtoApiModel[]): void {
    const arrangementsControls = this.arrangementsForm.controls.arrangements;
    arrangementsControls.clear();
    arrangements.forEach((arrangement) => {
      arrangementsControls.push(this.buildWindowArrangementFormGroup(arrangement));
    });
  }

  private buildWindowArrangementFormGroup({
    id,
    label,
    windowArrangement,
  }: Partial<WindowArrangementDtoApiModel>): WindowArrangementFormGroup {
    return this.fb.group({
      id: this.fb.control(id),
      label: this.fb.control(label, Validators.required),
      windowArrangement: this.fb.control(windowArrangement, Validators.required),
    });
  }

  private moveWindowArrangements(
    array: FormArray<WindowArrangementFormGroup>,
    previousIndex: number,
    currentIndex: number,
  ): void {
    const arrangement = array.at(previousIndex);
    array.removeAt(previousIndex);
    array.insert(currentIndex, arrangement);
  }

  private async checkIfWindowManagementPermissionGranted(): Promise<boolean> {
    try {
      const status = await navigator.permissions.query({ name: 'window-management' as PermissionName });
      if (status?.state !== 'granted') {
        return confirm(this.i18nService.instant('serviceDlp.windowMenu.noPermissions'));
      }

      return true;
    } catch (_error) {
      return false;
    }
  }

  private initFormEffects(): void {
    effect(() => {
      const searchText = this.arrangementsForm.controls.searchText;
      if (this.edit()) {
        searchText.setValue(undefined);
        searchText.disable();
      } else {
        searchText.enable();
      }
    });
  }

  private initWindowArrangements(): void {
    this.windowArrangementsApiService
      .getWindowArrangements()
      .pipe(
        tap((arrangements) => {
          this.setWindowArrangements(arrangements);
          this.originalArrangements = arrangements;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
