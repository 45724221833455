import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IGroupHeaderProps, IHeaderActionsRenderer } from 'dockview-core';
import { ButtonModule } from '@mship/design-ng/base/button';
import { TooltipModule } from '@mship/design-ng/base/tooltip';
import { DockviewGroupPanel } from 'dockview-core/dist/cjs/dockview/dockviewGroupPanel';
import { WindowManagerService } from '../../shared/window-manager/window-manager.service';
import { LaunchMenuAppEntry, LaunchMenuComponent } from '@mship/design-ng/custom/launch-menu';
import { TranslateModule } from '@ngx-translate/core';
import { LaunchMenuService } from '../../shared/launch-menu/launch-menu.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'dlp-left-header-action',
  standalone: true,
  imports: [CommonModule, ButtonModule, TooltipModule, TooltipModule, LaunchMenuComponent, TranslateModule],
  templateUrl: './left-header-action.component.html',
  styleUrl: './left-header-action.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftHeaderActionComponent implements IHeaderActionsRenderer {
  @ViewChild('element') elementRef!: ElementRef;

  params!: IGroupHeaderProps;
  launchMenuConfig = toSignal(this.launchMenuService.launchMenuConfig$, {
    initialValue: LaunchMenuService.EMPTY_LAUNCH_MENU,
  });

  constructor(
    private windowManagerService: WindowManagerService,
    private launchMenuService: LaunchMenuService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  get element() {
    this.changeDetectorRef.detectChanges();
    return this.elementRef.nativeElement;
  }

  dispose(): void {
    console.log('dispose');
  }

  init(params: IGroupHeaderProps): void {
    this.params = params;
  }

  openApp(launchMenuAppEntry: LaunchMenuAppEntry) {
    this.windowManagerService.openPanel(uuid(), launchMenuAppEntry.labels, launchMenuAppEntry.navigationCommands, {
      navigationExtras: launchMenuAppEntry.navigationExtras,
      position: {
        referenceGroup: this.params.group as DockviewGroupPanel,
      },
    });
  }
}
