<p-overlayPanel #windowMenu>
  <form class="service-dlp-window-menu" [formGroup]="arrangementsForm">
    <input
      type="text"
      pInputText
      formControlName="searchText"
      [placeholder]="'serviceDlp.windowMenu.search.placeholder' | translate"
    />
    <ul
      class="service-dlp-window-menu__arrangements p-card"
      cdkDropList
      (cdkDropListDropped)="onArrangementDrop($event)"
    >
      @for (
        arrangement of edit() ? arrangementsForm.controls.arrangements.controls : filteredArrangements();
        track arrangement
      ) {
        <li #windowArrangement class="service-dlp-window-menu__arrangements__arrangement" cdkDrag>
          @if (edit()) {
            <span
              class="service-dlp-window-menu__arrangements__arrangement__handle pi pi-bars"
              cdkDragHandle
              [title]="'serviceDlp.windowMenu.arrangements.actions.drag' | translate"
            ></span>
          }
          <input
            type="text"
            pInputText
            [formControl]="arrangement.controls.label"
            [placeholder]="'serviceDlp.windowMenu.arrangements.arrangement.placeholder' | translate"
            [readonly]="!edit()"
          />
          <div class="service-dlp-window-menu__arrangements__arrangement__actions">
            @if (edit()) {
              <p-button
                [title]="'serviceDlp.windowMenu.arrangements.actions.delete' | translate"
                [outlined]="true"
                severity="danger"
                icon="pi pi-trash"
                (click)="onArrangementDelete($index)"
              ></p-button>
            } @else {
              <p-button
                [title]="'serviceDlp.windowMenu.arrangements.actions.extend' | translate"
                [outlined]="true"
                icon="pi pi-external-link"
                (click)="onArrangementExtend(arrangement.value.windowArrangement)"
              ></p-button>
              <p-button
                [title]="'serviceDlp.windowMenu.arrangements.actions.replace' | translate"
                [outlined]="true"
                icon="pi pi-arrow-right-arrow-left"
                (click)="onArrangementReplace(arrangement.value.windowArrangement)"
              ></p-button>
            }
          </div>
        </li>
      } @empty {
        <p class="service-dlp-window-menu__arrangements__empty-message">
          {{ 'serviceDlp.windowMenu.arrangements.noData' | translate }}
        </p>
      }
    </ul>
    <div class="service-dlp-window-menu__actions">
      @if (edit()) {
        <p-button
          [label]="'serviceDlp.windowMenu.actions.cancelEditWindowArrangements' | translate"
          [outlined]="true"
          icon="pi pi-times"
          (click)="onCancel()"
        ></p-button>
      }
      <p-button
        [label]="'serviceDlp.windowMenu.actions.addCurrentArrangement' | translate"
        icon="pi pi-plus"
        (click)="onAdd()"
      ></p-button>
      @if (edit()) {
        <p-button
          [label]="'serviceDlp.windowMenu.actions.saveWindowArrangements' | translate"
          icon="pi pi-save"
          [disabled]="arrangementsForm.invalid"
          (click)="onSave()"
        ></p-button>
      } @else {
        <p-button
          [label]="'serviceDlp.windowMenu.actions.editWindowArrangements' | translate"
          icon="pi pi-pencil"
          (click)="onEdit()"
        ></p-button>
      }
    </div>
  </form>
</p-overlayPanel>
<span
  class="pi pi-table"
  [title]="'serviceDlp.windowMenu.openWindowMenu' | translate"
  (click)="windowMenu.toggle($event)"
></span>
