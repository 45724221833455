import { DestroyRef, ElementRef, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AddPanelPositionOptions, IGroupHeaderProps } from 'dockview-core';
import { DockviewGroupPanel } from 'dockview-core/dist/cjs/dockview/dockviewGroupPanel';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  first,
  firstValueFrom,
  forkJoin,
  map,
  of,
  tap,
  timeout,
} from 'rxjs';
import { MessageService } from '@mship/design-ng/base/api';
import { AuthService } from '@mship/design-ng/custom/auth';
import { WINDOW } from '@mship/design-ng/custom/api';
import { DockviewPanel, IDockviewPanel } from 'dockview-core/dist/cjs/dockview/dockviewPanel';
import { NavigationExtras } from '@angular/router';
import { ThemeService } from '@mship/design-ng/custom/theme';
import { I18nService, LocalizedTexts } from '@mship/design-ng/custom/i18n';
import { DockviewComponent } from 'dockview-core/dist/cjs/dockview/dockviewComponent';
import { v4 as uuid } from 'uuid';
import { WindowsState, WindowState } from './window-manager.interface';
import { FrameService } from '@mship/design-ng/custom/frame';
import { LaunchMenuService } from '../launch-menu/launch-menu.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ServiceWorkerUpdateService } from '@mship/design-ng/custom/service-worker-update';
import { NavigationCommandsTemplate } from '../launch-menu/launch-menu.interface';

@Injectable({
  providedIn: 'root',
})
export class WindowManagerService {
  panels: Map<string, ElementRef> = new Map();
  isDLPEnabled = new BehaviorSubject(true);

  private readonly dockview: Promise<DockviewComponent>;

  private initId = uuid();
  private windowChildren: Map<string, Window> = new Map();
  private currentGroup!: IGroupHeaderProps;

  private initChannel = new BroadcastChannel('mship-service-dlp-init');
  private removeChannel = new BroadcastChannel('mship-service-dlp-remove');
  private themeChannel = new BroadcastChannel('mship-service-dlp-theme');
  private i18nChannel = new BroadcastChannel('mship-service-dlp-i18n');

  private screenY = 0;
  private screenX = 0;
  private mouseDown = false;

  get isRootWindow(): boolean {
    return !this.window.windowId;
  }

  get isChildWindow(): boolean {
    return !!this.window.windowId;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private messageService: MessageService,
    private authService: AuthService,
    private i18nService: I18nService,
    private themeService: ThemeService,
    private frameService: FrameService,
    private launchMenuService: LaunchMenuService,
    private serviceWorkerUpdateService: ServiceWorkerUpdateService,
    private destroyRef: DestroyRef,
  ) {
    this.window.windowChildren = this.windowChildren;
    this.dockview = this.getDockview(this.window);

    this.initDuplicateRootListener();
    this.initMessageEventListener();
    this.initHeaderEventListener();
    this.initWindowRemoveListener();
    this.initWindowCloseListener();
    this.initMouseEventListener();
    this.initDockviewPanelChangeListener();
    this.initUpdateHandling();
  }

  openPanel(
    id: string,
    label: string | LocalizedTexts,
    navigationCommands: string[],
    options: {
      position?: AddPanelPositionOptions;
      params?: object;
      windowContext?: Window;
      navigationExtras?: NavigationExtras;
    },
  ): DockviewPanel;

  openPanel(
    id: string,
    label: string | LocalizedTexts,
    component: string,
    options: {
      position?: AddPanelPositionOptions;
      params?: object;
      windowContext?: Window;
      navigationExtras?: NavigationExtras;
    },
  ): DockviewPanel;

  openPanel(
    id: string,
    label: string | LocalizedTexts,
    toRender: string | string[],
    options: {
      position?: AddPanelPositionOptions;
      params?: object;
      windowContext?: Window;
      navigationExtras?: NavigationExtras;
    },
  ): DockviewPanel {
    options.position = options.position || {
      direction: 'left',
    };
    options.params = options.params || {};
    options.windowContext = options.windowContext || this.window;

    if (typeof toRender === 'string') {
      return options.windowContext.dockview.addPanel({
        id,
        component: toRender,
        renderer: 'always',
        position: options.position,
        params: {
          navigationCommands: toRender,
          orgTitle: label,
          ...options.params,
        },
      });
    } else {
      if (options.navigationExtras && options.navigationExtras.state?.['type'] === 'Component') {
        return options.windowContext.dockview.addPanel({
          id,
          component: toRender[0],
          renderer: 'always',
          position: options.position,
          params: {
            navigationCommands: toRender,
            navigationExtras: options.navigationExtras,
            orgTitle: label,
            ...options.params,
          },
        });
      }

      return options.windowContext.dockview.addPanel({
        id,
        component: 'IframePanelComponent',
        renderer: 'always',
        position: options.position,
        params: {
          navigationCommands: toRender,
          orgTitle: label,
          ...options.params,
        },
      });
    }
  }

  openDragAndDrop(group: IGroupHeaderProps): void {
    this.currentGroup = group;
    this.mouseDown = true;
  }

  removeWindow(windowId: string): void {
    this.removeChannel.postMessage({ windowId });
  }

  async getCurrentWindowsArrangement(): Promise<string | undefined> {
    return this.serializeWindowsState(await this.getWindowsState());
  }

  async fetchLocationForPanel(panelId: string): Promise<string | undefined> {
    const locations = await this.fetchLocationsFromPanels();
    return locations.get(panelId);
  }

  extendWindows(windowArrangement: string): void {
    const windowsState = this.deserializeWindowsState(windowArrangement);
    if (this.isRootWindow && this.isDLPEnabled.value && windowsState) {
      windowsState.forEach(async (state) => {
        await this.openNewWindow(state);
      });
    }
  }

  replaceWindows(windowArrangement: string): void {
    const windowsState = this.deserializeWindowsState(windowArrangement);
    if (this.isRootWindow && this.isDLPEnabled.value && windowsState) {
      this.getAllWindows(this.window)
        .slice(1)
        .forEach((window) => {
          window.close();
        });

      windowsState.forEach(async (state, windowId) => {
        if (windowId === 'root') {
          const dockview = await this.dockview;
          dockview.fromJSON(state.dockview);
        } else {
          await this.openNewWindow(state);
        }
      });
    }
  }

  private getDockview(window: Window): Promise<DockviewComponent> {
    return new Promise((resolve) => {
      if (window.dockview) {
        resolve(this.window.dockview);
      } else {
        const listener = (event: Event) => {
          window.removeEventListener('dockview-initialized', listener);
          resolve((event as CustomEvent<DockviewComponent>).detail);
        };

        window.addEventListener('dockview-initialized', listener);
      }
    });
  }

  private async getWindowsState(): Promise<WindowsState> {
    const panelLocations = await this.fetchLocationsFromPanels();
    const windows = this.getAllWindows(this.window);
    return windows.reduce((state, window) => {
      const id = window.windowId ? window.windowId : 'root';
      const dockview = window.dockview.toJSON();
      for (const key in dockview.panels) {
        const panel = dockview.panels[key];
        const location = panelLocations.get(panel.id);
        if (panel.params && location) {
          panel.params['navigationCommandsTemplate'] =
            this.launchMenuService.createNavigationCommandsTemplate(location);
        }

        if (panel.params && 'frame' in panel.params) {
          delete panel.params['frame'];
        }
      }

      state.set(id, {
        location: this.getWindowLocation(window),
        dockview,
      });
      return state;
    }, new Map<string, WindowState>());
  }

  private getAllWindows(window: Window): Window[] {
    const findAllWindows = (window: Window): Window[] => {
      const children: Window[] = [...(window.windowChildren?.values() ?? [])];
      return [window, ...children.flatMap((child) => findAllWindows(child))];
    };

    return findAllWindows(window);
  }

  private getWindowLocation(window: Window): string {
    return `left=${window.screenX.toString()},top=${window.screenY.toString()},width=${window.innerWidth},height=${window.innerHeight}`;
  }

  private serializeWindowsState(windowsState: WindowsState | undefined): string | undefined {
    if (!windowsState) {
      return;
    }

    return JSON.stringify(Object.fromEntries(windowsState));
  }

  private deserializeWindowsState(windowsState: string | null | undefined): WindowsState | undefined {
    if (!windowsState) {
      return;
    }

    try {
      const windowsStateMap = new Map<string, WindowState>(Object.entries(JSON.parse(windowsState)));
      windowsStateMap.forEach((windowState) => {
        Object.entries(windowState.dockview.panels).forEach(([, panel]) => {
          if (!panel.params?.['navigationCommandsTemplate']) {
            return;
          }

          const navigationCommandsTemplate: NavigationCommandsTemplate = panel.params['navigationCommandsTemplate'];
          const navigationCommands = this.launchMenuService.applyNavigationCommandsTemplate(navigationCommandsTemplate);
          if (navigationCommands) {
            panel.params['navigationCommands'] = navigationCommands;
          }
        });
      });

      return windowsStateMap;
    } catch (error) {
      console.warn('Invalid windows state found!', error);
      return;
    }
  }

  private sendMessageToPanels(id: string, message: unknown): void {
    this.panels.forEach((panel) => {
      panel.nativeElement.contentWindow.postMessage({ id, message }, panel.nativeElement.src);
    });
  }

  private async fetchLocationsFromPanels(): Promise<Map<string, string>> {
    const panels = [...this.panels];
    if (panels.length === 0) {
      return new Map();
    }

    const requests = panels.map(([id]) => {
      return this.frameService.registerBroadcastId(id).pipe(
        first(),
        timeout(100),
        catchError(() => of(undefined)),
      );
    });

    const result = firstValueFrom(
      forkJoin(requests).pipe(
        map((responses) => {
          return responses.reduce((map, messageEvent) => {
            if (messageEvent) {
              const { id, message } = messageEvent.data;
              map.set(id, message);
            }
            return map;
          }, new Map());
        }),
      ),
    );

    panels.forEach(([id, panel]) => {
      panel.nativeElement.contentWindow.postMessage({ id: 'app-shell', message: id }, panel.nativeElement.src);
    });

    return result;
  }

  private async openNewWindow(state: WindowState): Promise<void> {
    const newWindow = this.window.open(this.window.location.href, '_blank', state.location);
    if (newWindow) {
      const windowId = uuid();
      this.windowChildren.set(windowId, newWindow);
      newWindow.windowId = windowId;
      const dockview = await this.getDockview(newWindow);
      if (dockview) {
        dockview.fromJSON(state.dockview);
      }
    }
  }

  private initDuplicateRootListener(): void {
    if (this.isRootWindow) {
      this.initChannel.onmessage = (messageEvent) => {
        if (messageEvent.data.message === 'close') {
          this.isDLPEnabled.next(false);
          this.initChannel.onmessage = null;
        } else {
          if (this.initId !== messageEvent.data.id) {
            this.initChannel.postMessage({
              id: this.initId,
              message: 'close',
            });
          }
        }
      };
      this.initChannel.postMessage({ id: this.initId, message: 'init' });
    }
  }

  private initMessageEventListener(): void {
    this.window.onmessage = (messageEvent) => {
      if (messageEvent.data.id === 'init') {
        this.sendMessageToPanels('init', {
          issuer: this.authService.issuer,
          clientId: this.authService.clientId,
          granted_scopes: sessionStorage.getItem('granted_scopes'),
          id_token_claims_obj: sessionStorage.getItem('id_token_claims_obj'),
          session_state: sessionStorage.getItem('session_state'),
          PKCE_verifier: sessionStorage.getItem('PKCE_verifier'),
          access_token: sessionStorage.getItem('access_token'),
          id_token: sessionStorage.getItem('id_token'),
          id_token_stored_at: sessionStorage.getItem('id_token_stored_at'),
          access_token_stored_at: sessionStorage.getItem('access_token_stored_at'),
          id_token_expires_at: sessionStorage.getItem('id_token_expires_at'),
          expires_at: sessionStorage.getItem('expires_at'),
          nonce: sessionStorage.getItem('nonce'),
          refresh_token: sessionStorage.getItem('refresh_token'),
        });
      }
      if (messageEvent.data.id === 'openPanel') {
        this.openPanel(
          uuid(),
          messageEvent.data.title ?? window.dockview.activePanel?.title ?? ' ',
          [messageEvent.data.href],
          {
            position: {
              direction: messageEvent.data.panelDirection,
              referencePanel: window.dockview.activePanel,
            },
          },
        );
        window.focus();
      }
    };
  }

  private initHeaderEventListener(): void {
    this.themeService.currentTheme$
      .pipe(
        distinctUntilChanged(),
        tap((theme) => {
          this.sendMessageToPanels('theme', theme);
          this.themeChannel.postMessage(theme);
        }),
      )
      .subscribe();
    this.themeChannel.addEventListener('message', (event) => {
      this.themeService.useTheme(event.data);
    });

    this.i18nService.currentLanguage$
      .pipe(
        distinctUntilChanged(),
        tap((lang) => {
          this.sendMessageToPanels('i18n', lang);
          this.i18nChannel.postMessage(lang);
        }),
      )
      .subscribe();
    this.i18nChannel.addEventListener('message', (event) => {
      this.i18nService.changeLanguage(event.data).subscribe();
    });
  }

  private initWindowRemoveListener(): void {
    this.removeChannel.onmessage = (event) => {
      if (event.data.windowId) {
        this.windowChildren.delete(event.data.windowId);
      }
    };
  }

  private initWindowCloseListener(): void {
    this.window.addEventListener('beforeunload', () => {
      if (this.isRootWindow) {
        const windows = this.getAllWindows(this.window);
        windows.slice(1).forEach((window) => {
          window.close();
        });
      } else {
        this.removeWindow(this.window.windowId);
      }
    });
  }

  private initMouseEventListener(): void {
    this.document.addEventListener('mousemove', (event) => {
      this.screenY = event.screenY;
      this.screenX = event.screenX;
    });

    this.document.addEventListener('mouseup', async () => {
      if (this.mouseDown) {
        const appLocation = `left=${this.screenX.toString()},top=${this.screenY.toString()},width=${this.currentGroup.group.width},height=${this.currentGroup.group.height}`;
        const newWindow = this.window.open(this.window.location.origin, '_blank', appLocation);
        if (newWindow) {
          newWindow.windowId = uuid();
          this.windowChildren.set(newWindow.windowId, newWindow);
          this.mouseDown = false;

          const windowDockview = await this.dockview;
          const newWindowDockview = await this.getDockview(newWindow);
          if (newWindowDockview) {
            const newGroup = newWindowDockview.addGroup();
            this.currentGroup.group.panels.forEach((panel: IDockviewPanel) => {
              this.openPanel(panel.id || uuid(), panel.params?.['orgTitle'] ?? '', panel.api.component || '', {
                position: {
                  referenceGroup: newGroup as DockviewGroupPanel,
                },
                params: panel.params || {},
                windowContext: newWindow,
              });
            });
            windowDockview.removeGroup(this.currentGroup.group as DockviewGroupPanel);
          }
        }
      }
    });
  }

  private initDockviewPanelChangeListener(): void {
    this.dockview.then((dockview) => {
      if (dockview) {
        dockview.onDidAddPanel((panel) => {
          if (panel.id === 'notification-panel') {
            this.messageService.add({
              severity: 'info',
              summary: 'Preliminary UI while refining business requirements',
              closable: false,
            });
          } else {
            this.messageService.add({
              severity: 'info',
              summary: 'Init panel',
              detail: panel.params?.['src'],
            });
          }
        });
      }
    });
  }

  private initUpdateHandling(): void {
    this.serviceWorkerUpdateService.versionUpdateAvailable$
      .pipe(
        filter((available) => !!available),
        tap(() => this.sendMessageToPanels('update', undefined)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
