import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IDockviewPanel } from 'dockview-core/dist/cjs/dockview/dockviewPanel';
import { WindowManagerService } from '../shared/window-manager/window-manager.service';

@Component({
  selector: 'dlp-notification-manager',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification-manager.component.html',
  styleUrl: './notification-manager.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationManagerComponent {
  notificationPanel: IDockviewPanel | undefined;

  constructor(private windowManagerService: WindowManagerService) {}

  showNotification() {
    if (
      window.dockview.panels.some((panel) => {
        return panel.id === 'notification-panel' ? (this.notificationPanel = panel) : false;
      })
    ) {
      this.notificationPanel?.focus();
    } else {
      this.windowManagerService.openPanel(
        'notification-panel',
        'serviceDlp.launchMenu.notifications',
        'NotificationPanelComponent',
        {
          position: {
            direction: 'right',
          },
        },
      );
    }
  }
}
