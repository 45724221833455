import { clamp } from '../math';
export class ViewItem {
  set size(size) {
    this._size = size;
  }
  get size() {
    return this._size;
  }
  get cachedVisibleSize() {
    return this._cachedVisibleSize;
  }
  get visible() {
    return typeof this._cachedVisibleSize === 'undefined';
  }
  get minimumSize() {
    return this.visible ? this.view.minimumSize : 0;
  }
  get viewMinimumSize() {
    return this.view.minimumSize;
  }
  get maximumSize() {
    return this.visible ? this.view.maximumSize : 0;
  }
  get viewMaximumSize() {
    return this.view.maximumSize;
  }
  get priority() {
    return this.view.priority;
  }
  get snap() {
    return !!this.view.snap;
  }
  set enabled(enabled) {
    this.container.style.pointerEvents = enabled ? '' : 'none';
  }
  constructor(container, view, size, disposable) {
    this.container = container;
    this.view = view;
    this.disposable = disposable;
    this._cachedVisibleSize = undefined;
    if (typeof size === 'number') {
      this._size = size;
      this._cachedVisibleSize = undefined;
      container.classList.add('visible');
    } else {
      this._size = 0;
      this._cachedVisibleSize = size.cachedVisibleSize;
    }
  }
  setVisible(visible, size) {
    var _a;
    if (visible === this.visible) {
      return;
    }
    if (visible) {
      this.size = clamp((_a = this._cachedVisibleSize) !== null && _a !== void 0 ? _a : 0, this.viewMinimumSize, this.viewMaximumSize);
      this._cachedVisibleSize = undefined;
    } else {
      this._cachedVisibleSize = typeof size === 'number' ? size : this.size;
      this.size = 0;
    }
    this.container.classList.toggle('visible', visible);
    if (this.view.setVisible) {
      this.view.setVisible(visible);
    }
  }
  dispose() {
    this.disposable.dispose();
    return this.view;
  }
}