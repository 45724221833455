import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultTab } from 'dockview-core';
import { ButtonModule } from '@mship/design-ng/base/button';
import { TooltipModule } from '@mship/design-ng/base/tooltip';
import { LaunchMenuComponent } from '@mship/design-ng/custom/launch-menu';
import { GroupPanelPartInitParameters } from 'dockview-core/dist/cjs/dockview/types';
import { I18nService, LocalizedTextService } from '@mship/design-ng/custom/i18n';
import { Subject, takeUntil, tap } from 'rxjs';
import { ContextMenu, ContextMenuModule } from '@mship/design-ng/base/contextmenu';
import { MenuItem } from '@mship/design-ng/base/api';
import { TranslateModule } from '@ngx-translate/core';
import { WindowManagerService } from '../../shared/window-manager/window-manager.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'dlp-tab',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TooltipModule,
    TooltipModule,
    LaunchMenuComponent,
    ContextMenuModule,
    TranslateModule,
  ],
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent extends DefaultTab {
  @ViewChild('contextMenu') contextMenu: ContextMenu | undefined;

  params!: GroupPanelPartInitParameters;
  items: MenuItem[] = [
    {
      label: 'serviceDlp.panels.tap.contextMenu.duplicate',
      icon: 'pi pi-clone',
      command: async () => {
        const location = await this.windowManagerService.fetchLocationForPanel(this.params.api.id);
        this.windowManagerService.openPanel(
          uuid(),
          this.params.params['orgTitle'],
          location ? [location] : this.params.params['navigationCommands'],
          {
            position: {
              referenceGroup: this.params.api.group,
            },
          },
        );
      },
    },
  ];
  orgLabels = this.items.map((item) => item.label);

  private language = toSignal(this.i18nService.currentLanguage$);
  private destroy = new Subject<void>();

  override get element(): HTMLElement {
    this.params.api.setTitle(this.getTitle(this.params));
    const element = super.element;
    this.changeDetectorRef.detectChanges();
    if (this.contextMenu) {
      element.addEventListener('contextmenu', (event) => this.contextMenu?.show(event));
    }
    return element;
  }

  constructor(
    private i18nService: I18nService,
    private localizedTextService: LocalizedTextService,
    private changeDetectorRef: ChangeDetectorRef,
    private windowManagerService: WindowManagerService,
  ) {
    super();
    this.i18nService.currentLanguage$
      .pipe(
        tap(() => {
          this.items.forEach((item, index) => {
            item.label = this.i18nService.instant(this.orgLabels[index] || item.label || '');
          });
          if (this.params) {
            this.params.api.setTitle(this.getTitle(this.params));
          }
        }),
        takeUntil(this.destroy),
      )
      .subscribe();
  }

  override init(params: GroupPanelPartInitParameters): void {
    this.params = params;
    super.init(params);
  }

  override dispose(): void {
    super.dispose();
    this.destroy.next();
    this.destroy.complete();
  }

  private getTitle(params: GroupPanelPartInitParameters): string {
    const orgTitle = params.params['orgTitle'];
    return typeof orgTitle === 'string'
      ? this.i18nService.instant(orgTitle)
      : this.localizedTextService.translate(orgTitle, this.language());
  }
}
