<p-card [header]="'serviceDlp.gridStatusDashboard.title' | translate">
  <p-chart #chart width="200px" height="200px" type="pie" [data]="data" [options]="options"></p-chart>
  <p-messages
    [value]="status()"
    [enableService]="false"
    [closable]="false"
    [hideTransitionOptions]="'0ms'"
    [showTransitionOptions]="'0ms'"
  ></p-messages>
</p-card>
