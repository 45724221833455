import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { PanelsComponent } from './panels/panels.component';
import { ContextMenuModule } from '@mship/design-ng/base/contextmenu';
import { MenuItem } from '@mship/design-ng/base/api';
import { WindowManagerService } from './shared/window-manager/window-manager.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { AppShellComponent } from '@mship/design-ng/custom/app-shell';
import { LaunchMenuAppEntry } from '@mship/design-ng/custom/launch-menu';
import { NotificationManagerComponent } from './notification-manager/notification-manager.component';
import { InstanceMenuComponent } from './instance-menu/instance-menu.component';
import { WINDOW } from '@mship/design-ng/custom/api';
import { TranslateModule } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { LaunchMenuService } from './shared/launch-menu/launch-menu.service';
import { WindowMenuComponent } from './window-menu/window-menu.component';
import { OverlayPanelModule } from '@mship/design-ng/base/overlaypanel';
import { v4 as uuid } from 'uuid';

@Component({
  standalone: true,
  imports: [
    AppShellComponent,
    PanelsComponent,
    ContextMenuModule,
    AsyncPipe,
    NotificationManagerComponent,
    InstanceMenuComponent,
    NgIf,
    TranslateModule,
    WindowMenuComponent,
    OverlayPanelModule,
  ],
  selector: 'dlp-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  isRootWindow = this.windowManagerService.isRootWindow;
  isChildWindow = this.windowManagerService.isChildWindow;
  isDLPEnabled = this.windowManagerService.isDLPEnabled.asObservable();

  stage = this.window.location.origin
    .replace('https://mship-service-dlp-ui.', '')
    .replace('.bearingpoint.partner.mship.energy', '')
    .toUpperCase();
  version = '0.0.0';

  items: MenuItem[] = [
    {
      label: 'Events',
      icon: 'pi pi-fw pi-calendar',
      items: [
        {
          label: 'Remove',
          icon: 'pi pi-fw pi-calendar-minus',
        },
        {
          label: 'Archive',
          icon: 'pi pi-fw pi-calendar-times',
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: 'Close',
      icon: 'pi pi-fw pi-power-off',
    },
  ];
  launchMenuConfig = toSignal(this.launchMenuService.launchMenuConfig$, {
    initialValue: LaunchMenuService.EMPTY_LAUNCH_MENU,
  });
  favoritesAllowed = toSignal(this.launchMenuService.favoritesAllowed$);

  constructor(
    @Inject(WINDOW) private window: Window,
    private windowManagerService: WindowManagerService,
    private launchMenuService: LaunchMenuService,
  ) {}

  openApp(launchMenuAppEntry: LaunchMenuAppEntry): void {
    this.windowManagerService.openPanel(uuid(), launchMenuAppEntry.labels, launchMenuAppEntry.navigationCommands, {
      navigationExtras: launchMenuAppEntry.navigationExtras,
    });
  }

  toggleFavorite(launchMenuAppEntry: LaunchMenuAppEntry): void {
    this.launchMenuService.toggleFavorite(launchMenuAppEntry);
  }
}
