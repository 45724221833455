import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ToastModule } from '@mship/design-ng/base/toast';
import { MessagesModule } from '@mship/design-ng/base/messages';
import { IContentRenderer } from 'dockview-core';
import { GroupPanelPartInitParameters } from 'dockview-core/dist/cjs/dockview/types';

@Component({
  selector: 'dlp-notification-panel',
  standalone: true,
  imports: [ToastModule, MessagesModule],
  templateUrl: './notification-panel.component.html',
  styleUrl: './notification-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPanelComponent implements IContentRenderer {
  @ViewChild('element') elementRef!: ElementRef;

  params!: GroupPanelPartInitParameters;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  get element() {
    this.changeDetectorRef.detectChanges();
    return this.elementRef.nativeElement;
  }

  init(groupPanelInitParameters: GroupPanelPartInitParameters): void {
    this.params = groupPanelInitParameters;
  }
}
