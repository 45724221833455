import { positionToDirection } from '../dnd/droptarget';
import { Emitter } from '../events';
import { MutableDisposable } from '../lifecycle';
import { GridviewPanelApiImpl } from './gridviewPanelApi';
const NOT_INITIALIZED_MESSAGE = 'dockview: DockviewGroupPanelApiImpl not initialized';
export class DockviewGroupPanelApiImpl extends GridviewPanelApiImpl {
  get location() {
    if (!this._group) {
      throw new Error(NOT_INITIALIZED_MESSAGE);
    }
    return this._group.model.location;
  }
  constructor(id, accessor) {
    super(id, '__dockviewgroup__');
    this.accessor = accessor;
    this._mutableDisposable = new MutableDisposable();
    this._onDidLocationChange = new Emitter();
    this.onDidLocationChange = this._onDidLocationChange.event;
    this._onDidActivePanelChange = new Emitter();
    this.onDidActivePanelChange = this._onDidActivePanelChange.event;
    this.addDisposables(this._onDidLocationChange, this._onDidActivePanelChange, this._mutableDisposable);
  }
  close() {
    if (!this._group) {
      return;
    }
    return this.accessor.removeGroup(this._group);
  }
  getWindow() {
    return this.location.type === 'popout' ? this.location.getWindow() : window;
  }
  moveTo(options) {
    var _a, _b, _c;
    if (!this._group) {
      throw new Error(NOT_INITIALIZED_MESSAGE);
    }
    const group = (_a = options.group) !== null && _a !== void 0 ? _a : this.accessor.addGroup({
      direction: positionToDirection((_b = options.position) !== null && _b !== void 0 ? _b : 'right'),
      skipSetActive: true
    });
    this.accessor.moveGroupOrPanel({
      from: {
        groupId: this._group.id
      },
      to: {
        group,
        position: options.group ? (_c = options.position) !== null && _c !== void 0 ? _c : 'center' : 'center'
      }
    });
  }
  maximize() {
    if (!this._group) {
      throw new Error(NOT_INITIALIZED_MESSAGE);
    }
    if (this.location.type !== 'grid') {
      // only grid groups can be maximized
      return;
    }
    this.accessor.maximizeGroup(this._group);
  }
  isMaximized() {
    if (!this._group) {
      throw new Error(NOT_INITIALIZED_MESSAGE);
    }
    return this.accessor.isMaximizedGroup(this._group);
  }
  exitMaximized() {
    if (!this._group) {
      throw new Error(NOT_INITIALIZED_MESSAGE);
    }
    if (this.isMaximized()) {
      this.accessor.exitMaximizedGroup();
    }
  }
  initialize(group) {
    /**
     * TODO: Annoying initialization order caveat, find a better way to initialize and avoid needing null checks
     *
     * Due to the order on initialization we know that the model isn't defined until later in the same stack-frame of setup.
     * By queuing a microtask we can ensure the setup is completed within the same stack-frame, but after everything else has
     * finished ensuring the `model` is defined.
     */
    this._group = group;
    queueMicrotask(() => {
      this._mutableDisposable.value = this._group.model.onDidActivePanelChange(event => {
        this._onDidActivePanelChange.fire(event);
      });
    });
  }
}