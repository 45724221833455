import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IContentRenderer } from 'dockview-core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GroupPanelPartInitParameters } from 'dockview-core/dist/cjs/dockview/types';
import { WindowManagerService } from '../../../shared/window-manager/window-manager.service';
import { LaunchMenuService } from '../../../shared/launch-menu/launch-menu.service';

@Component({
  selector: 'dlp-iframe-panel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './iframe-panel.component.html',
  styleUrl: './iframe-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframePanelComponent implements IContentRenderer, AfterViewInit {
  @ViewChild('element') elementRef!: ElementRef;

  params!: GroupPanelPartInitParameters;
  src: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('');

  get element() {
    this.changeDetectorRef.detectChanges();
    this.windowManagerService.panels.set(this.params.api.id, this.elementRef);
    this.params.params['frame'] = this.elementRef;
    return this.elementRef.nativeElement;
  }

  constructor(
    private domSanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private windowManagerService: WindowManagerService,
    private launchMenuService: LaunchMenuService,
  ) {}

  ngAfterViewInit() {
    (this.elementRef?.nativeElement as HTMLIFrameElement)?.contentWindow?.addEventListener('focus', () =>
      this.params.api.setActive(),
    );
  }

  init(groupPanelInitParameters: GroupPanelPartInitParameters): void {
    this.params = groupPanelInitParameters;
    const url = this.getUrl(groupPanelInitParameters);
    this.src = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  dispose() {
    this.windowManagerService.panels.delete(this.params.api.id);
  }

  private getUrl(params: GroupPanelPartInitParameters): string {
    const { navigationCommands } = params?.params ?? {};
    return navigationCommands.join('');
  }
}
