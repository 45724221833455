import { ApplicationConfig, isDevMode } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { provideMship } from '@mship/design-ng';
import { provideServiceWorker } from '@angular/service-worker';
import { MessageService } from '@mship/design-ng/base/api';
import { environment } from '../environments/environment';
import { BASE_PATH } from '@mship/service-dlp-api';
import { provideRouter } from '@angular/router';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter([]), // Currently needed because the error component from mship-design-ui requires the router.
    provideMship({
      api: {
        appId: 'mship-service-dlp',
        loadConfigJson: environment.production,
      },
      auth: {
        config: {
          issuer: 'http://127.0.0.1:7001/realms/na-realm',
          clientId: 'na-dlp-ui',
          requireHttps: environment.production,
        },
        httpInterceptors: [
          {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
          },
        ],
      },
      authorization: {
        config: {
          baseObjectType: 'dlp',
          requireAnyRole: ['na-dlp-user'],
        },
      },
      openDlpPanel: {
        config: {
          interceptContextMenu: false,
        },
      },
    }),
    MessageService,
    {
      provide: BASE_PATH,
      useValue: '.',
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
