import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowManagerService } from '../shared/window-manager/window-manager.service';
import { WINDOW } from '@mship/design-ng/custom/api';

@Component({
  selector: 'dlp-instance-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './instance-menu.component.html',
  styleUrl: './instance-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstanceMenuComponent {
  constructor(
    @Inject(WINDOW) private window: Window,
    private windowManagerService: WindowManagerService,
  ) {}

  closeAndRemove() {
    if (this.window.confirm('Close view?')) {
      this.windowManagerService.removeWindow(this.window.windowId);
      this.window.close();
    }
  }
}
