import { addDisposableListener } from '../../../events';
import { toggleClass } from '../../../dom';
import { CompositeDisposable } from '../../../lifecycle';
import { createCloseButton } from '../../../svg';
export class Watermark extends CompositeDisposable {
  get element() {
    return this._element;
  }
  constructor() {
    super();
    this._element = document.createElement('div');
    this._element.className = 'watermark';
    const title = document.createElement('div');
    title.className = 'watermark-title';
    const emptySpace = document.createElement('span');
    emptySpace.style.flexGrow = '1';
    const content = document.createElement('div');
    content.className = 'watermark-content';
    this._element.appendChild(title);
    this._element.appendChild(content);
    const actionsContainer = document.createElement('div');
    actionsContainer.className = 'actions-container';
    const closeAnchor = document.createElement('div');
    closeAnchor.className = 'close-action';
    closeAnchor.appendChild(createCloseButton());
    actionsContainer.appendChild(closeAnchor);
    title.appendChild(emptySpace);
    title.appendChild(actionsContainer);
    this.addDisposables(addDisposableListener(closeAnchor, 'click', event => {
      var _a;
      event.preventDefault();
      if (this._group) {
        (_a = this._api) === null || _a === void 0 ? void 0 : _a.removeGroup(this._group);
      }
    }));
  }
  init(_params) {
    this._api = _params.containerApi;
    this._group = _params.group;
    this.render();
  }
  render() {
    const isOneGroup = !!(this._api && this._api.size <= 1);
    toggleClass(this.element, 'has-actions', isOneGroup);
  }
}