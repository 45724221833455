import { CompositeDisposable } from '../../../lifecycle';
import { addDisposableListener, Emitter } from '../../../events';
import { Tab } from '../tab/tab';
import { VoidContainer } from './voidContainer';
import { toggleClass } from '../../../dom';
import { WillShowOverlayLocationEvent } from '../../dockviewGroupPanelModel';
import { getPanelData } from '../../../dnd/dataTransfer';
export class TabsContainer extends CompositeDisposable {
  get panels() {
    return this.tabs.map(_ => _.value.panel.id);
  }
  get size() {
    return this.tabs.length;
  }
  get hidden() {
    return this._hidden;
  }
  set hidden(value) {
    this._hidden = value;
    this.element.style.display = value ? 'none' : '';
  }
  show() {
    if (!this.hidden) {
      this.element.style.display = '';
    }
  }
  hide() {
    this._element.style.display = 'none';
  }
  setRightActionsElement(element) {
    if (this.rightActions === element) {
      return;
    }
    if (this.rightActions) {
      this.rightActions.remove();
      this.rightActions = undefined;
    }
    if (element) {
      this.rightActionsContainer.appendChild(element);
      this.rightActions = element;
    }
  }
  setLeftActionsElement(element) {
    if (this.leftActions === element) {
      return;
    }
    if (this.leftActions) {
      this.leftActions.remove();
      this.leftActions = undefined;
    }
    if (element) {
      this.leftActionsContainer.appendChild(element);
      this.leftActions = element;
    }
  }
  setPrefixActionsElement(element) {
    if (this.preActions === element) {
      return;
    }
    if (this.preActions) {
      this.preActions.remove();
      this.preActions = undefined;
    }
    if (element) {
      this.preActionsContainer.appendChild(element);
      this.preActions = element;
    }
  }
  get element() {
    return this._element;
  }
  isActive(tab) {
    return this.selectedIndex > -1 && this.tabs[this.selectedIndex].value === tab;
  }
  indexOf(id) {
    return this.tabs.findIndex(tab => tab.value.panel.id === id);
  }
  constructor(accessor, group) {
    super();
    this.accessor = accessor;
    this.group = group;
    this.tabs = [];
    this.selectedIndex = -1;
    this._hidden = false;
    this._onDrop = new Emitter();
    this.onDrop = this._onDrop.event;
    this._onTabDragStart = new Emitter();
    this.onTabDragStart = this._onTabDragStart.event;
    this._onGroupDragStart = new Emitter();
    this.onGroupDragStart = this._onGroupDragStart.event;
    this._onWillShowOverlay = new Emitter();
    this.onWillShowOverlay = this._onWillShowOverlay.event;
    this._element = document.createElement('div');
    this._element.className = 'tabs-and-actions-container';
    toggleClass(this._element, 'dv-full-width-single-tab', this.accessor.options.singleTabMode === 'fullwidth');
    this.rightActionsContainer = document.createElement('div');
    this.rightActionsContainer.className = 'right-actions-container';
    this.leftActionsContainer = document.createElement('div');
    this.leftActionsContainer.className = 'left-actions-container';
    this.preActionsContainer = document.createElement('div');
    this.preActionsContainer.className = 'pre-actions-container';
    this.tabContainer = document.createElement('div');
    this.tabContainer.className = 'tabs-container';
    this.voidContainer = new VoidContainer(this.accessor, this.group);
    this._element.appendChild(this.preActionsContainer);
    this._element.appendChild(this.tabContainer);
    this._element.appendChild(this.leftActionsContainer);
    this._element.appendChild(this.voidContainer.element);
    this._element.appendChild(this.rightActionsContainer);
    this.addDisposables(this.accessor.onDidAddPanel(e => {
      if (e.api.group === this.group) {
        toggleClass(this._element, 'dv-single-tab', this.size === 1);
      }
    }), this.accessor.onDidRemovePanel(e => {
      if (e.api.group === this.group) {
        toggleClass(this._element, 'dv-single-tab', this.size === 1);
      }
    }), this._onWillShowOverlay, this._onDrop, this._onTabDragStart, this._onGroupDragStart, this.voidContainer, this.voidContainer.onDragStart(event => {
      this._onGroupDragStart.fire({
        nativeEvent: event,
        group: this.group
      });
    }), this.voidContainer.onDrop(event => {
      this._onDrop.fire({
        event: event.nativeEvent,
        index: this.tabs.length
      });
    }), this.voidContainer.onWillShowOverlay(event => {
      this._onWillShowOverlay.fire(new WillShowOverlayLocationEvent(event, {
        kind: 'header_space',
        panel: this.group.activePanel,
        api: this.accessor.api,
        group: this.group,
        getData: getPanelData
      }));
    }), addDisposableListener(this.voidContainer.element, 'pointerdown', event => {
      const isFloatingGroupsEnabled = !this.accessor.options.disableFloatingGroups;
      if (isFloatingGroupsEnabled && event.shiftKey && this.group.api.location.type !== 'floating') {
        event.preventDefault();
        const {
          top,
          left
        } = this.element.getBoundingClientRect();
        const {
          top: rootTop,
          left: rootLeft
        } = this.accessor.element.getBoundingClientRect();
        this.accessor.addFloatingGroup(this.group, {
          x: left - rootLeft + 20,
          y: top - rootTop + 20,
          inDragMode: true
        });
      }
    }), addDisposableListener(this.tabContainer, 'pointerdown', event => {
      if (event.defaultPrevented) {
        return;
      }
      const isLeftClick = event.button === 0;
      if (isLeftClick) {
        this.accessor.doSetGroupActive(this.group);
      }
    }));
  }
  setActive(_isGroupActive) {
    // noop
  }
  addTab(tab, index = this.tabs.length) {
    if (index < 0 || index > this.tabs.length) {
      throw new Error('invalid location');
    }
    this.tabContainer.insertBefore(tab.value.element, this.tabContainer.children[index]);
    this.tabs = [...this.tabs.slice(0, index), tab, ...this.tabs.slice(index)];
    if (this.selectedIndex < 0) {
      this.selectedIndex = index;
    }
  }
  delete(id) {
    const index = this.tabs.findIndex(tab => tab.value.panel.id === id);
    const tabToRemove = this.tabs.splice(index, 1)[0];
    const {
      value,
      disposable
    } = tabToRemove;
    disposable.dispose();
    value.dispose();
    value.element.remove();
  }
  setActivePanel(panel) {
    this.tabs.forEach(tab => {
      const isActivePanel = panel.id === tab.value.panel.id;
      tab.value.setActive(isActivePanel);
    });
  }
  openPanel(panel, index = this.tabs.length) {
    var _a;
    if (this.tabs.find(tab => tab.value.panel.id === panel.id)) {
      return;
    }
    const tab = new Tab(panel, this.accessor, this.group);
    if (!((_a = panel.view) === null || _a === void 0 ? void 0 : _a.tab)) {
      throw new Error('invalid header component');
    }
    tab.setContent(panel.view.tab);
    const disposable = new CompositeDisposable(tab.onDragStart(event => {
      this._onTabDragStart.fire({
        nativeEvent: event,
        panel
      });
    }), tab.onChanged(event => {
      const isFloatingGroupsEnabled = !this.accessor.options.disableFloatingGroups;
      const isFloatingWithOnePanel = this.group.api.location.type === 'floating' && this.size === 1;
      if (isFloatingGroupsEnabled && !isFloatingWithOnePanel && event.shiftKey) {
        event.preventDefault();
        const panel = this.accessor.getGroupPanel(tab.panel.id);
        const {
          top,
          left
        } = tab.element.getBoundingClientRect();
        const {
          top: rootTop,
          left: rootLeft
        } = this.accessor.element.getBoundingClientRect();
        this.accessor.addFloatingGroup(panel, {
          x: left - rootLeft,
          y: top - rootTop,
          inDragMode: true
        });
        return;
      }
      const isLeftClick = event.button === 0;
      if (!isLeftClick || event.defaultPrevented) {
        return;
      }
      if (this.group.activePanel !== panel) {
        this.group.model.openPanel(panel);
      }
    }), tab.onDrop(event => {
      this._onDrop.fire({
        event: event.nativeEvent,
        index: this.tabs.findIndex(x => x.value === tab)
      });
    }), tab.onWillShowOverlay(event => {
      this._onWillShowOverlay.fire(new WillShowOverlayLocationEvent(event, {
        kind: 'tab',
        panel: this.group.activePanel,
        api: this.accessor.api,
        group: this.group,
        getData: getPanelData
      }));
    }));
    const value = {
      value: tab,
      disposable
    };
    this.addTab(value, index);
  }
  closePanel(panel) {
    this.delete(panel.id);
  }
  dispose() {
    super.dispose();
    for (const {
      value,
      disposable
    } of this.tabs) {
      disposable.dispose();
      value.dispose();
    }
    this.tabs = [];
  }
}